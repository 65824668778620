import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-thin-loader',
  templateUrl: 'thin-loader.component.html',
  styleUrls: ['thin-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThinLoaderComponent {
}
