import { ChangeDetectorRef, Directive, inject, OnInit, ViewChild } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { filter, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';

import {
  AuthService,
  NotificationCenterService,
  NotificationsService
} from '@app/core/services';
import {
  Notification,
  NotificationDialogData,
  NotificationParams,
  NotificationPriority,
  NotificationType,
  NotificationUnreadCountResponse
} from '@app/models/notifications/notification.model';
import { isNullOrUndefined, LoadDataTrigger, getRemainingScrollDistanceToBottom, cloneDeep } from '@app/shared/helper';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { NOTIFICATION_TYPES } from '@app/shared/constants';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';

@UntilDestroy()
@Directive()
export abstract class BaseNotifications<C> implements OnInit {
  @ViewChild(CdkScrollable) container: CdkScrollable;

  readonly FIRST_LOAD_COUNT: number = 30;
  readonly SCROLL_LOAD_COUNT: number = 20;

  readonly LoadDataTrigger = LoadDataTrigger;

  readonly notificationTypes = NOTIFICATION_TYPES;

  pagination: PaginationIfc = new PaginationIfc(this.FIRST_LOAD_COUNT, 0, 0);
  notifications: Notification[];
  isLoading: boolean;

  hasNext: boolean;

  trigger: LoadDataTrigger;

  selectedNotificationType: NotificationType | 'all';

  protected data: NotificationDialogData = inject<NotificationDialogData>(MAT_DIALOG_DATA);
  protected dialogRef: MatDialogRef<C> = inject<MatDialogRef<C>>(MatDialogRef<C>);
  protected authService: AuthService = inject<AuthService>(AuthService);
  protected notificationCenterService: NotificationCenterService = inject<NotificationCenterService>(NotificationCenterService);
  protected notificationsService: NotificationsService = inject<NotificationsService>(NotificationsService);
  protected cd: ChangeDetectorRef = inject<ChangeDetectorRef>(ChangeDetectorRef);

  get userId(): number {
    return this.authService.profile.id;
  }

  ngOnInit(): void {
    this.subscribeToNewNotification();
    this.subscribeToRemoveNotifications();

    this.getNotifications(LoadDataTrigger.Initial);
  }

  private subscribeToNewNotification(): void {
    this.notificationCenterService.newNotification
      .pipe(
        untilDestroyed(this)
      ).subscribe((newNotification: Notification) => {
        if (newNotification.priority_type === this.data.priority && !this.notifications.some(n => n.id === newNotification.id)) {
          this.handleNewNotificationAdded(newNotification);
        }
      });
  }

  private subscribeToRemoveNotifications(): void {
    this.notificationCenterService.notificationsCount
      .pipe(
        filter(() => !!this.notifications?.length),
        untilDestroyed(this)
      ).subscribe((value: NotificationUnreadCountResponse) => {
        this.handleRemoveReadNotifications(value);
      });
  }

  protected handleNewNotificationAdded(newNotification: Notification): void {
    this.notifications = [newNotification, ...this.notifications];
    this.pagination.countChanged(this.pagination.total + 1);
    this.cd.markForCheck();
  }

  protected handleRemoveReadNotifications(value: NotificationUnreadCountResponse): void {
    const newIds = this.data.priority === NotificationPriority.Critical ? value.unread_critical_ids : value.unread_regular_ids;
    const newCount = this.data.priority === NotificationPriority.Critical ? value.unread_critical_count : value.unread_regular_count;
    this.notifications = this.notifications.filter(n => newIds.includes(n.id));
    this.pagination.countChanged(newCount);
    this.cd.markForCheck();
  }

  protected getNotifications(trigger: LoadDataTrigger): void {
    this.trigger = trigger;

    this.setLimitAndOffset();

    const params: NotificationParams = {
      priority_type: this.data.priority,
      ...this.pagination.getParams()
    };

    if (!isNullOrUndefined(this.selectedNotificationType)) {
      params.notification_type = this.selectedNotificationType !== 'all' ? [this.selectedNotificationType] : this.notificationTypes.filter(n => n.value !== 'all').map(n => n.value) as NotificationType[];
    }

    this.isLoading = true;
    this.notificationCenterService.getNotifications(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        }),
        untilDestroyed(this)
      ).subscribe((response: PaginatedResponse<Notification>) => {
        this.handleResponseSuccess(response);
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  protected handleResponseSuccess(response: PaginatedResponse<Notification>): void {
    const newSortedNotifications = response.results.sort((a, b) => moment(new Date(a.created)).isAfter(new Date(b.created)) ? -1 : 1);
    this.notifications = this.trigger === LoadDataTrigger.Initial ? newSortedNotifications : [...this.notifications, ...newSortedNotifications];
    this.pagination.countChanged(response.count);
    this.hasNext = !!response.next;
  }

  protected cleanUp(): void {
    this.trigger = LoadDataTrigger.Initial;

    this.isLoading = true;
    this.notificationCenterService.clearAllNotifications({ priority_type: this.data.priority })
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        })
      ).subscribe(() => {
        this.handleCleanUpResponseSuccess();
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  protected handleCleanUpResponseSuccess(): void {
    this.notifications = [];
    this.pagination.countChanged(0);
    this.hasNext = false;
  }

  protected deleteNotification(id: number): void {
    this.trigger = LoadDataTrigger.Initial;

    this.isLoading = true;
    this.notificationCenterService.deleteNotification(id)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        })
      ).subscribe(() => {
        this.handleDeleteNotificationResponseSuccess();
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  protected handleDeleteNotificationResponseSuccess(): void {
    this.notificationsService.showSuccess('notificationCenter.notification_notificationWasDeleted');
  }

  protected markNotificationAsRead(id: number): void {
    this.notificationCenterService.markAsRead(id, { mark_as_read: true })
      .pipe(
        untilDestroyed(this),
        finalize(() => this.cd.detectChanges())
      ).subscribe((response: Notification) => {
        this.handleMarkAsReadResponseSuccess(response);
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  protected handleMarkAsReadResponseSuccess(response: Notification): void {
    
  }

  protected onScroll(): void {
    const scrollContainerRef = this.container.getElementRef();
    const atBottom = getRemainingScrollDistanceToBottom(scrollContainerRef.nativeElement) === 0;
    if (this.hasNext && atBottom && !this.isLoading) {
      this.getNotifications(LoadDataTrigger.Scroll);
    }
  }

  protected close(): void {
    this.dialogRef.close();
  }

  private setLimitAndOffset(): void {
    if (this.trigger === LoadDataTrigger.Initial) {
      this.pagination.limitChanged(this.FIRST_LOAD_COUNT);
      this.pagination.offsetChanged(0);
    } else {
      this.pagination.offsetChanged(this.pagination.offset + this.pagination.limit);
      this.pagination.limitChanged(this.SCROLL_LOAD_COUNT);
    }
  }
}
