<div #scrollContainer cdkScrollable (scroll)="onScroll(scrollContainer)" class="scroll-container">
  <div class="filtration-section" [formGroup]="filtrationForm">
    <input class="search-input"
           type="text"
           placeholder="Search"
           appTrimInput
           formControlName="search"/>

    <ng-select class="app-ng-select chat-type-select"
               formControlName="lookup_field"
               appearance="outline"
               appendTo="body"
               bindLabel="title"
               bindValue="value"
               [items]="chatTypes"
               [clearable]="false"
               [searchable]="false">
      <ng-template ng-label-tmp ng-option-tmp let-item="item">
        {{ item.title | translate }}
      </ng-template>
    </ng-select>
  </div>

  <ng-container *ngIf="!isLoadingWithReplace">
    <div *ngFor="let chat of items; trackBy: trackById; let chatIndex = index"
         class="chat"
         [class.active]="activeChatId === chat.id"
         (click)="onChatClick(chat)">
      <app-user-avatar
        [name]="chat.name"
        [avatar]="chat.image"
      ></app-user-avatar>
      <div class="chat__card">
        <div class="chat__title">
          <span *ngIf="chat.conversation_type === ConversationType.group"
                [matTooltip]="'messageCenter.conversationType_group' | translate"
                class="material-icons-outlined icon-type">group</span>

          <span *ngIf="chat.conversation_type === ConversationType.direct"
                [matTooltip]="'messageCenter.conversationType_direct' | translate"
                class="material-icons-outlined icon-type">person</span>
          <div class="chat__name ellipsis">{{ chat.name }}</div>

          <div *ngIf="!!chat.last_message.created_at" class="chat__time">
            {{ formatDate(chat.last_message.created_at) }}
          </div>

        </div>

        <div class="chat__note" [class.unread]="!!chat.unread_messages_ids?.length">
          <div class="chat__message" *ngIf="chat.last_message.text">{{ chat.last_message.text }}</div>
          <mat-icon *ngIf="!chat.last_message.text && chat.last_message.has_files" class="material-icons image-icon">
            image
          </mat-icon>
          <div class="chat__unread">{{ chat.unread_messages_ids?.length }}</div>
        </div>
      </div>

      <mat-icon *ngIf="chat.id"
                class="material-icons-outlined icon-delete"
                [matTooltip]="'messageCenter.deleteChat' | translate"
                (click)="deleteChat(chat); $event.stopPropagation()">
        delete
      </mat-icon>
    </div>
  </ng-container>

  <app-loading *ngIf="isLoading"></app-loading>
</div>
