import { MasterWeekVisitWidget } from '@app/models/patient/masterweek.model';
import { PatientWidget, UserWidget } from '@app/models/widgets.model';

import { BlastingStatus } from "@app/models/blasting/blasting-visit.model";

export interface BlastedVisit {
  readonly id: number;
  number: string;
  status: BlastingStatus;
  status_value: string;
  start_date_time: string;
  end_date_time: string;
  created_at: string;
  creator: number;
  creator_detail: UserWidget;
  patient: number;
  patient_detail: PatientWidget;
  readonly total_count: number;
  new_offers_count: number;
  readonly offers_count: number;
  readonly sent_count: number;
  time_is_running_out: boolean;
}

export interface NestedBlastedVisit {
  readonly id: number;
  caregiver: number;
  caregiver_detail: UserWidget;
  readonly service_name: string;
  assigned_visits: MasterWeekVisitWidget[];
  coordinated_visits: any[];
  viewed: boolean;
  status: NestedBlastingStatus;
  readonly status_value: string;
  readonly total_count: number;
  accepted_visits_count: number;
  is_new: boolean;
  has_overlapping: boolean;
  overtime_after: number;
  projection_hours_per_week: number;
  projection_minutes_per_week: number;
  projection_hours_per_week_before: number;
  projection_minutes_per_week_before: number;
}

export interface MWAssignApproveAction {
  mw_assign: number;
  selected_accepted_visits: number[];
}

export enum NestedBlastingStatus {
  Accepted,
  DeclinedOffer,
  PendingOffer,
  Missed,
  Coordinated,
  Canceled,
}
