import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { OptionsMultiselectEvent } from '@app/shared/fields/multiselect-field/models';
import { BaseSelectComponent } from '@app/shared/fields/base-select/select.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

@Component({
  selector: 'app-multiselect-field',
  templateUrl: 'multiselect-field.component.html',
  styleUrls: ['multiselect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectFieldComponent),
      multi: true
    },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'app-multiselect-overlay' } },
  ]
})
export class MultiselectFieldComponent extends BaseSelectComponent implements OnInit, ControlValueAccessor {
  @Input() translateLabel = false;
  @Input() showValueAsCounter = false;
  @Input() searchable = false;

  // The behavior when the component shows value as 'All' should be disabled if we are using a lazy load strategy with initialOptions.
  // So before the moment, we load all options we can't know is all options selected.
  // Maybe better to determine this behavior depending on whether initialOptions was defined instead of setting it manually.
  @Input() disableOptionsIfAllChecked: boolean = false;
  @Input() showValueAsAllWhenEveryOptionSelected = true;
  @Input() showToggleAllOption = false;
  @Input() autoselectAllAfterFirstLoad = false;
  @Input() placeholder: string = 'placeholders.noSelectedOptions';
  @Input() allOptionLabel = 'common.all';
  @Input() set viewInputPrefix(value: string) {
    this.inputPrefix = value ? `${ value }: ` : '';
  }

  @Output() optionSelect: EventEmitter<OptionsMultiselectEvent> = new EventEmitter<OptionsMultiselectEvent>();

  value: any[] = [];
  selectedLabels: string[] = [];
  selectedOptions: object[] = [];
  inputPrefix: string = '';
  searchControl = new FormControl('');

  get isAllOptionsSelected() {
    return this.value?.length >= this.totalItemsWithoutFiltering;
  }

  get showSelectedAllWithoutLoadedItems(): boolean {
    return this.autoselectAllAfterFirstLoad && !this.optionsWereLoadedAtLeastOnce;
  }

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(this.SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((query) => this.loadOptions({ search: encodeURIComponent(query) }));
  }

  onOptionSelected(): void {
    this.updateInputView();
    this.onChangeCallback(this.value);
    this.optionSelect.emit({
      newValues: this.value,
      optionsData: this.selectedOptions,
      changedByAutoselect: false,
      isSelectedAll: this.value.length === this.totalItemsWithoutFiltering,
    });
  }

  toggleAllOptions(): void {
    this.value = this.isAllOptionsSelected ? [] : this.options.map(option => option[this.bindValue]);
    this.onOptionSelected();
    this.cdr.markForCheck();
  }

  protected override onOptionsLoad(response: PaginatedResponse) {
    super.onOptionsLoad(response);
    if (!this.optionsWereLoadedAtLeastOnce && this.autoselectAllAfterFirstLoad) {
      this.autoselectAllItems();
    }
  }

  protected override updateInputView(): void {
    this.selectedOptions = (this.value ?? [])
      .map((value) => [...this.selectedOptions, ...this.options].find(option => option[this.bindValue] === value))
      .filter(Boolean);
    this.selectedLabels = this.selectedOptions.map(option => {
      const label = option ? option[this.bindLabel] : null;
      return this.translateLabel && label ? this.translateService.instant(label) : label;
    });
  }

  private autoselectAllItems(): void {
    this.writeValue(this.options.map(option => option[this.bindValue]));
    this.onChangeCallback(this.value);
    this.optionSelect.emit({
      newValues: this.value,
      optionsData: this.selectedOptions,
      changedByAutoselect: true,
      isSelectedAll: true
    });
  }
}
