import { ChangeDetectionStrategy, Component, forwardRef, inject, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TravelInfo } from '@app/models/patient/visit-details/travel-time';

@Component({
  selector: 'app-travel-time-form',
  templateUrl: './travel-time-form.component.html',
  styleUrls: ['./travel-time-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: forwardRef(() => inject(ControlContainer, { skipSelf: true })),
    }
  ]
})
export class TravelTimeFormComponent {
  @Input() travelInfo: TravelInfo;
  @Input() rateEditable = true;
  @Input() disabled = false;

  @Output() timeDurationSave = new EventEmitter<void>();

  saveTimeDuration () {
    this.timeDurationSave.emit();
  }
}
