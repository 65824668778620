import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LoadedMessageFile, Message } from '../models/message.model';
import { ConversationType, UserChat } from '../models/chat.model';
import { oneOfValidator } from '../shared/validators';
import { MessageStatus, MessageType } from '../shared/constants';
import { User } from '../models/user.model';
import { ChatService } from '../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@app/core/services';

@UntilDestroy()
@Component({
  selector: 'app-create-new-message',
  templateUrl: './create-new-message.component.html',
  styleUrls: ['./create-new-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showHideClients', [
      state('show', style({ height: '*' })),
      state('hide', style({ height: '0px' })),
      transition('show <=> hide', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class CreateNewMessageComponent implements OnInit {
  form: FormGroup;
  loadedFiles: LoadedMessageFile[] = [];
  readonly conversationsTypes = ConversationType;
  isShowClients: boolean = false;
  clients: User[] = [];
  clientsForEmail: User[] = [];
  isSending: boolean;
  readonly messagesTypes = MessageType;

  readonly messagesTypesOptions = [
    { value: MessageType.sms, title: 'messageCenter.messageType_sms' },
    { value: MessageType.email, title: 'messageCenter.messageType_email' },
    { value: MessageType.internal, title: 'messageCenter.messageType_text' }
  ];

  constructor(
    private dialogRef: MatDialogRef<CreateNewMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public userChat: UserChat,
    private fb: FormBuilder,
    private chatService: ChatService,
    private notificationsService: NotificationsService,
  ) {
  }

  get type(): FormControl {
    return this.form.get('type') as FormControl;
  }

  get theme(): FormControl {
    return this.form.get('theme') as FormControl;
  }

  get files(): FormArray {
    return this.form.get('files') as FormArray;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.userChat.conversation_type === ConversationType.group) {
      this.clients = this.userChat.members;
      this.clientsForEmail = this.userChat.members;
    }
  }

  removeClient(clientId: number): void {
    if (this.clients.length === 1) {
      return;
    }
    this.clients = this.clients.filter(client => client.id !== clientId);
    this.clientsForEmail = this.clients;
  }

  private initForm(): void {
    this.form = this.fb.group({
        type: [null, Validators.required],
        theme: '',
        message: '',
        files: this.fb.array([])
      },
      {
        validators: [oneOfValidator('message', 'files')]
      });
  }

  addFiles(event: LoadedMessageFile[]): void {
    this.loadedFiles = event;
    this.updateFilesInForm();
  }

  private updateFilesInForm(): void {
    this.files.clear();
    this.loadedFiles.forEach(file => {
      this.files.push(this.addFileControl(file));
    });
  }

  private addFileControl(file: LoadedMessageFile): FormGroup {
    return this.fb.group({
      name: [file.name],
      file: [file.file]
    });
  }

  removeAttachedFile(index: number): void {
    const idx = this.loadedFiles.findIndex(f => f.index === index);
    this.loadedFiles.splice(idx, 1);
    this.updateFilesInForm();
  }

  send(): void {
    this.isSending = true;
    const form = this.form.value;
    const type = +form.type;

    let recipients = [];
    if (this.userChat.conversation_type === ConversationType.direct) {
      recipients = [this.userChat.direct_to.id];
    } else {
      if (type === MessageType.email) {
        recipients = this.clientsForEmail.map(client => client.id);
      } else {
        recipients = this.clients.map(client => client.id);
      }
    }
    const payload = {
      chat: this.userChat.id,
      status: MessageStatus.created,
      channel: type,
      text: form.message,
      files_data: form.files.map(file => {
        return {
          file: file.file
        };
      }),
      recipients
    };
    if (type === MessageType.email) {
      payload['subject'] = form.theme;
    }
    this.chatService.sendMessage(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: Message) => this.close(),
        error => this.notificationsService.showError(error)
      );
  }

  changeType(type: MessageType): void {
    if (type === MessageType.email) {
      this.clients = this.clientsForEmail;
      this.theme.setValidators(Validators.required);
    } else {
      this.clients = this.userChat.members;
      this.theme.clearValidators();
    }
    this.theme.updateValueAndValidity();
  }

  toggleClients(): void {
    this.isShowClients = !this.isShowClients;
  }

  close(): void {
    this.dialogRef.close();
  }
}
