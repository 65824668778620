import { Injectable } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { Websocket, WsConnectionParams, WsCreationParams } from '@app/core/services/websocket/websocket';
import { AuthService } from '@app/core/services';
import { WsConnectionModule, WsData } from '@app/core/services/websocket/ws.models';
import { MessageCenterAuthService } from '@app/pages/message-center/services';
import { MESSAGE_CENTER_URLS } from '@app/pages/message-center/shared/MESSAGE_CENTER_URLS';
import { environment } from '../../../../environments/environment';
import { URLS } from '@app/shared/urls';

interface OpenedWebsocket {
  websocket: Websocket;
  subscriptions: number;
}

export type ModuleSubscriptionParams = Omit<WsConnectionParams, 'company_id'>;

const MESSAGE_CENTER_MODULES: WsConnectionModule[] = [
  WsConnectionModule.chat,
  WsConnectionModule.listOfChats,
  WsConnectionModule.notifications
];

export class ModuleSubscription {
  public moduleMessages$: Observable<MessageEvent>;

  private isUnsubscribed = false;

  constructor(private realtime: RealtimeService,
              private params: ModuleSubscriptionParams,
              public websocket: Websocket) {
    this.moduleMessages$ = websocket.message$.pipe(takeWhile(() => !this.isUnsubscribed));
  }

  unsubscribe(): void {
    if (!this.isUnsubscribed) {
      this.realtime.unsubscribeFromModule(this.params);
      this.isUnsubscribed = true;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class RealtimeService {
  private websockets: { [key: string]: OpenedWebsocket } = {};
  private messagesSubject: Subject<MessageEvent> = new Subject<MessageEvent>();

  // Common messages from all subscribed modules.
  // If you need to receive messages only from separate websocket connection then use 'moduleMessages$' inside ModuleSubscription instance
  public messages$: Observable<MessageEvent> = this.messagesSubject.asObservable();

  constructor(private authService: AuthService,
              private mcAuthService: MessageCenterAuthService,) {
  }

  subscribeToModule(params: ModuleSubscriptionParams): ModuleSubscription {
    const key = this.generateConnectionKey(params);

    if (this.websockets[key]) {
      this.onSubscribedToExistedModule(key);
    } else {
      this.onSubscribedToNewModule(key, params);
    }

    return new ModuleSubscription(this, params, this.websockets[key].websocket);
  }

  private onSubscribedToExistedModule(key: string): void {
    this.websockets[key].subscriptions++;
  }

  private onSubscribedToNewModule(key: string, params: ModuleSubscriptionParams): void {
    const companyId = this.isMessageCenterModule(params.module) ? this.mcAuthService.getProfile().company.id : this.authService.profile.companyId;

    const websocket = new Websocket(this.getCreationParams(params.module), { ...params, company_id: companyId });
    this.websockets[key] = {
      websocket,
      subscriptions: 1
    };

    websocket.disconnect$.subscribe(() => delete this.websockets[key]);
    websocket.message$.subscribe((message) => this.messagesSubject.next(message));
  }

  unsubscribeFromModule(params: ModuleSubscriptionParams): void {
    const key = this.generateConnectionKey(params);

    if (!this.websockets[key]) {
      return;
    }

    this.websockets[key].subscriptions--;
    if (this.websockets[key].subscriptions < 1) {
      this.websockets[key]?.websocket.disconnect();
    }
  }

  emitInternalEvent(data: WsData): void {
    const event: MessageEvent = {
      data: JSON.stringify(data),
      origin: location.origin
    } as MessageEvent;

    this.messagesSubject.next(event);
  }

  generateConnectionKey(params: ModuleSubscriptionParams): string {
    return Object.entries(params)
      .sort(([key1], [key2]) => key1 < key2 ? 1 : -1)
      .reduce((acc, [key, value]) => `${ acc }${ key }:${ value }.`, '');
  }

  private getCreationParams(module: WsConnectionModule): WsCreationParams {
    const isMessageCenterModule = this.isMessageCenterModule(module);

    return {
      url: isMessageCenterModule ? MESSAGE_CENTER_URLS.socket : URLS.socket,
      token: isMessageCenterModule ? this.mcAuthService.getToken() : this.authService.getToken(),
      secret: isMessageCenterModule ? environment.mcWSSecret : environment.baseWsSecret,
    };
  }

  private isMessageCenterModule(module: WsConnectionModule): boolean {
    return MESSAGE_CENTER_MODULES.includes(module);
  }
}
