import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from '@app/core/services';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { Recipient } from '@app/models/hr/mailing.model';
import { GetUsersRequestParams } from '@app/models/users/user.ifc';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class ProfilesOptionsLoader implements OptionsLoader {
  constructor(private userService: UserService) {
  }

  getOptions(params?: GetUsersRequestParams): Observable<PaginatedResponse<Recipient>> {
    return this.userService.getUsers({
      limit: 15,
      widget: 'fk',
      lookup_field: 'full_name',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
