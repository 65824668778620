export enum GeneralStatus {
  AcceptingOffer,
  Active,
  Applicant,
  Available,
  Cancelled,
  Closed,
  Completed,
  Coordinated,
  DeclinedOffer,
  Discharged,
  EligibleForRehire,
  EnRoute,
  Expired,
  Hold,
  HospitalizedAdmitted,
  HospitalizedObservation,
  Inactive,
  InProgress,
  MedicalLeave,
  Missed,
  NotCompleted,
  Offered,
  Open,
  PendingOffer,
  Rejected,
  Scheduled,
  TerminatedInvoluntary,
  TerminatedVoluntary,
  Unscheduled
}