import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HttpService } from '@app/core/services/http/http.service';
import { Notification, NotificationParams, NotificationPriority, NotificationUnreadCountResponse } from '@app/models/notifications/notification.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService {

  notificationsCount: BehaviorSubject<NotificationUnreadCountResponse> = new BehaviorSubject<NotificationUnreadCountResponse>(null);
  newNotification: Subject<Notification> = new Subject<Notification>();

  constructor(private http: HttpService) { }

  getNotifications(params: NotificationParams): Observable<PaginatedResponse<Notification>> {
    return this.http.GET<PaginatedResponse<Notification>>(URLS.notifications, params);
  }

  deleteNotification(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.notifications }${ id }/`);
  }

  markAsRead(id: number, data: { mark_as_read: boolean }): Observable<Notification> {
    return this.http.PATCH<Notification>(`${ URLS.notifications }${ id }/`, data);
  }

  clearAllNotifications(params: { priority_type: NotificationPriority }): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.notifications }clear_all/`, params);
  }

  getUnreadCount(): Observable<NotificationUnreadCountResponse> {
    return this.http.GET<NotificationUnreadCountResponse>(`${ URLS.notifications }unread_count/`);
  }

  // Read/unread notifications
  addNewUnreadNotification(notification: Notification): void {
    const { value } = this.notificationsCount;
    const criticalIds = value.unread_critical_ids;
    const regularIds = value.unread_regular_ids;

    if (notification.priority_type === NotificationPriority.Critical) {
      criticalIds.push(notification.id)
    } else {
      regularIds.push(notification.id);
    }

    this.newNotification.next(notification);
    this.updateUnreadCounts(criticalIds, regularIds);
  }

  removeReadNotifications(removedIds: number[]): void {
    const { value } = this.notificationsCount;
    const newCriticalIds = value.unread_critical_ids.filter(id => !removedIds.includes(id));
    const newRegularIds = value.unread_regular_ids.filter(id => !removedIds.includes(id));
    this.updateUnreadCounts(newCriticalIds, newRegularIds);
  }

  updateUnreadCounts(newCriticalIds: number[], newRegularIds: number[]): void {
    this.notificationsCount.next({
      unread_critical_ids: newCriticalIds,
      unread_critical_count: newCriticalIds.length,
      unread_regular_ids: newRegularIds,
      unread_regular_count: newRegularIds.length
    });
  }
}
