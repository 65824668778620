import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { LoadedMessageFile } from '../../../models/message.model';
import { allowedMIMETypes } from '../../constants';
import { toBase64 } from '@app/common';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {
  @Input() type: 'button' | 'icon' | 'field' = 'button';
  @Input() fieldSize: string;
  @Input() urlImg: string;
  @Input() multiple: boolean = true;
  @Output() filesAdded = new EventEmitter<LoadedMessageFile[]>();
  @ViewChild('fileInput') fileInput: ElementRef;

  readonly allowedMIMETypes = allowedMIMETypes;

  async fileUploaded(event: Event): Promise<void> {
    const loadedFiles: LoadedMessageFile[] = [];

    if (!this.multiple) {
      this.removeAvatar(event);
    }
    const files = (event.target as HTMLInputElement).files;

    for (let i = 0; i < files.length; i++) {
      await toBase64(files[i]).then(file => {
        loadedFiles.push({
          index: i,
          name: files[i].name,
          file
        });
      });
    }

    this.filesAdded.emit(loadedFiles);

    if (this.type === 'field') {
      this.urlImg = loadedFiles[0]?.file;
    }

    this.fileInput.nativeElement.value = null;
  }

  removeAvatar(event: Event): void {
    event.stopPropagation();
    this.urlImg = undefined;
    this.filesAdded.emit([]);
  }

  openDialogUpload(): void {
    this.fileInput.nativeElement.click();
  }
}
