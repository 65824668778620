import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MessageFile } from '../models/message.model';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewImageComponent implements OnInit {
  srcImg: string;
  index: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number, files: MessageFile[] }
  ) { }

  ngOnInit(): void {
    this.data.files.forEach((file, i) => {
      if (file.id === this.data.id) {
        this.index = i;
      }
    });
    this.changeImageSrc();
  }

  changeImageSrc(): void {
    this.srcImg = this.data.files[this.index].file;
  }

  next(): void {
    this.index++;
    this.changeImageSrc();
  }

  prev(): void {
    this.index--;
    this.changeImageSrc();
  }

}
