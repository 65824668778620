<app-loading *ngIf="isLoading" class="loading"></app-loading>

<div class="blasting-table" *ngIf="!isLoading && items.length">
  <mat-table [dataSource]="items" [trackBy]="trackById">
    <ng-container matColumnDef="isNew">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>

      <mat-cell *matCellDef="let row">
        <span *ngIf="row.is_new" class="mark">{{ 'activeBlasting.tab_tempVisit_nestedVisit_column_new' | translate }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="caregiver">
      <mat-header-cell *matHeaderCellDef>{{ 'activeBlasting.tab_tempVisit_nestedVisit_column_caregiver' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row">
        <a target="_blank" [routerLink]="['/', 'users', row.caregiver_detail.id, 'edit']">{{ row.caregiver_detail.full_name }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="service">
      <mat-header-cell *matHeaderCellDef>{{ 'activeBlasting.tab_tempVisit_nestedVisit_column_service' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{ row.service_name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="distance">
      <mat-header-cell *matHeaderCellDef>{{ 'activeBlasting.tab_tempVisit_nestedVisit_column_distance' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{ row.distance }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>{{ 'activeBlasting.tab_tempVisit_nestedVisit_column_status' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row">
        <app-status-pill
          [label]="row.status_value"
          [status]="row.status"
          [Enum]="NestedBlastingStatus">
        </app-status-pill>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>

      <mat-cell *matCellDef="let row" [class.status-not-open]="offerStatus !== 0">
        <div class="status-btns" [class.disabled]="row.status === 1 || row.status === 4">
          <button class="icon" matRipple (click)="approveOffer(row.id)">
            <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
          </button>
          <button class="icon" matRipple (click)="rejectOffer(row.id)">
            <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  
  <app-no-results *ngIf="!items.length && !isLoading"></app-no-results>
</div>

<app-paginator
  class="paginator"
  [hidden]="isLoading || !pagination.total"
  [total]="pagination.total"
  [perPage]="pagination.limit"
  [setPageNumber]="pagination.pageNumberDetector"
  (paginatorConfigChanged)="loadItems()"
  (offsetWasChanged)="pagination.offsetChanged($event)"
  (limitWasChanged)="pagination.limitChanged($event)"
></app-paginator>
