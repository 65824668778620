import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocompleteFieldComponent } from './autocomplete-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '@app/shared/components/loading/loading.module';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    AngularSvgIconModule,
    TranslateModule,
    LoadingModule,
  ],
  declarations: [
    AutocompleteFieldComponent,
  ],
  exports: [
    AutocompleteFieldComponent
  ]
})
export class AutocompleteFieldModule {}
