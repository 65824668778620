import * as jose from 'jose';
import type { JWTPayload } from 'jose/dist/types/types';

export class JwtGenerator {
  static async generateJWT(token: string, secret: string): Promise<string> {
    const encodedSecret = new TextEncoder().encode(secret);
    const alg = 'HS256';

    return await new jose.SignJWT({ token })
      .setProtectedHeader({ alg })
      .sign(encodedSecret);
  }

  static decodeJWT<T = any>(jwt: string): JWTPayload & T {
    return jose.decodeJwt(jwt) as any;
  }
}
