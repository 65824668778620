import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IdNameWidgetResponse } from '@app/models/response.model';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class OfficesOptionsLoader implements OptionsLoader {
  constructor(private httpService: HttpService) {
  }

  getOptions(params?: object): Observable<PaginatedResponse<IdNameWidgetResponse>> {
    return this.httpService.GET(URLS.office, {
      lookup_field: 'name',
      widget: 'fk',
      limit: 15,
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
