import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRippleModule } from '@angular/material/core';
import { IMaskModule } from 'angular-imask';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { SizedTableComponent } from './sized-table.component';
import { FieldsModule } from '@app/shared/fields';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { AvatarModule } from '@app/shared/avatar/avatar.module';
import { LoadingModule } from "@app/shared/components/loading/loading.module";
import { StatusPillModule } from '../components/status-pill/status-pill.module';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    MatRippleModule,
    IMaskModule,
    TranslateModule,
    MatIconModule,
    FieldsModule,
    PipesModule,
    AvatarModule,
    RouterModule,
    LoadingModule,
    StatusPillModule
  ],
  declarations: [
    SizedTableComponent,
  ],
  exports: [
    SizedTableComponent
  ]
})
export class SizedTableModule {
}
