import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  BulkChangeProvider,
  BulkChangeStatus,
  EDIHistory,
  EDIRevision,
  EDISubmitResponse,
  EdiClaimProviderInfo,
  EdiClaimProviderInfoParams,
  EdiDownload,
  EdiDownloadResponse,
  EdiSubmit,
  PayerInvoiceClaim,
  PayerInvoiceNestedDetails,
  PayerInvoiceNestedEDI,
  PayerInvoicing
} from '@app/models/payer/invoicing.model';
import { ResponseSuccess } from '@app/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class InvoicingService {

  constructor(
    private http: HttpService
  ) { }

  getPayerInvoicing(params: object): Observable<PaginatedResponse<PayerInvoicing>> {
    return this.http.GET<PaginatedResponse<PayerInvoicing>>(URLS.payer_invoice_batch, params);
  }

  getPayerInvoiceClaim(params: object): Observable<PaginatedResponse<PayerInvoiceClaim>> {
    return this.http.GET<PaginatedResponse<PayerInvoiceClaim>>(`${ URLS.payer_invoice_claim }`, params);
  }

  getPayerInvoiceNestedDetails(payerId: number, batchId: string): Observable<PayerInvoiceNestedDetails> {
    return this.http.GET<PayerInvoiceNestedDetails>(`${ URLS.payer_invoice_claim }${ payerId }/?batch_id=${ batchId }`);
  }

  getElectronicDataInterchange(payerId: number, batchId: string): Observable<PayerInvoiceNestedEDI> {
    return this.http.GET<PayerInvoiceNestedEDI>(`${ URLS.payer_invoice_claim }${ payerId }/electronic_data_interchange/?batch_id=${ batchId }`);
  }

  getEdiHistory(claimId: number, params: object): Observable<PaginatedResponse<EDIHistory>> {
    return this.http.GET<PaginatedResponse<EDIHistory>>(`${ URLS.history }edi_claim/${ claimId }/`, params);
  }

  submitInvoiceClaim(payerId: number, batchId: string): Observable<EDISubmitResponse> {
    return this.http.POST<EDISubmitResponse>(`${ URLS.payer_invoice_claim }${ payerId }/submit/?batch_id=${ batchId }`, {});
  }

  changeInvoiceClaimStatus(payerId: number, payload: EDIRevision): Observable<EDIRevision> {
    return this.http.PUT<EDIRevision>(`${ URLS.payer_invoice_claim_status }${ payerId }/`, payload);
  }

  getEdiClaimsProviderInfo(id: number, params: EdiClaimProviderInfoParams): Observable<EdiClaimProviderInfo[]> {
    return this.http.GET<EdiClaimProviderInfo[]>(`${ URLS.payer_invoice_batch }${ id }/edi_claims_providers_info/`, params);
  }

  submitEdi(id: number, data: EdiSubmit): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.payer_invoice_batch }${ id }/edi_submit/`, data);
  }

  downloadEdi(id: number, data: EdiDownload): Observable<EdiDownloadResponse> {
    return this.http.POST<EdiDownloadResponse>(`${ URLS.payer_invoice_batch }${ id }/edi_download_837/`, data);
  }

  bulkChangeProvider(data: BulkChangeProvider[]): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.payer_invoice_claim }bulk_change_provider/`, data);
  }

  bulkChangeStatus(data: BulkChangeStatus): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.payer_invoice_claim }bulk_change_status/`, data);
  }
}
