import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() text: string;
  @Input() imageUrl: string;
  @Input() size: number;
  @Input() borderRadius: number;
  @Input() backgroundColor: string;
}
