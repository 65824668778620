import { Component, Input } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { TreeNodeRoute } from '@app/models/tree-node.model';
import { Router } from '@angular/router';

interface MenuFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

export type MenuNodeNotificationsDictionary = { [nodeName: string]: number };

@Component({
  selector: 'app-panel-editor-menu',
  templateUrl: './editor-menu.component.html',
  styleUrls: ['./editor-menu.component.scss']
})
export class EditorPanelMenuComponent {

  @Input() menuNodes: TreeNodeRoute[];
  @Input() nodesWithNotifications: MenuNodeNotificationsDictionary;

  constructor(private router: Router) {}

  private transformer = (node: TreeNodeRoute, level: number): TreeNodeRoute & { expandable: boolean, level: number } => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      marked: node.marked,
      disabled: node.disabled,
      notifications: node.notifications,
      level,
      route: node.route
    };
  }

  treeControl = new FlatTreeControl<MenuFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnChanges() {
    if (this.menuNodes) {
      this.dataSource.data = this.menuNodes;
      this.treeControl.expandAll();
    }
  }

  hasChild = (_: number, node: MenuFlatNode) => node.expandable;

  getNotifications(nodeName: string): number | undefined {
    return this.nodesWithNotifications && this.nodesWithNotifications[nodeName];
  }

}
