import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() name: string;
  @Input() avatar: string;
  @Input() iconSize: string = '56px';
  @Input() radius: string = '50%';

  getFirstLetterName(name: string): string {
    return name.charAt(0).toUpperCase();
  }
}
