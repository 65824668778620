import { Observable, of } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { TranslateService } from '@ngx-translate/core';
import { SearchRequestParams } from '@app/models/request.model';
import { RootInjector } from '@app/root-injector';

export class StaticOptionsLoader<T = object> implements OptionsLoader<T> {
  constructor(private options: T[]) {
  }

  getOptions(params?: SearchRequestParams): Observable<PaginatedResponse<T>> {
    const translate: TranslateService = RootInjector.injector.get(TranslateService);

    if (params?.search) {
      if (!params.lookup_field) {
        throw new Error('You must specify a lookup_field');
      }

      return of({
        results: this.options.filter(option => translate.instant(option[params.lookup_field]).includes(params.search)),
        count: this.options.length,
      });
    }

    return of({
      results: [...this.options],
      count: this.options.length
    });
  }
}
