import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { ObservableCache } from '@app/core/cache';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { inject } from '@angular/core';

const cache = new ObservableCache<PaginatedResponse>();

export class AnimalsOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  getOptions(): Observable<PaginatedResponse> {
    return cache.get(URLS.animal, this.httpService.GET<PaginatedResponse>(URLS.animal)
      .pipe(toPaginatedResponse())
    );
  }
}
