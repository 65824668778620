import { ApplicantStatus, GeneralStatus } from '@app/core/enums';
import { StatusesMapper } from '@app/models/statuses-mapper.model';

// TODO: add propriate statuses when there are colors for it
export const APPLICANT_STATUSES_MAPPER: StatusesMapper = {
  [ApplicantStatus.New]: GeneralStatus.Active,
  [ApplicantStatus.Pending]: GeneralStatus.Active,
  [ApplicantStatus.InterviewScheduling]: GeneralStatus.Active,
  [ApplicantStatus.InterviewScheduled]: GeneralStatus.Active,
  [ApplicantStatus.InterviewArrived]: GeneralStatus.Active,
  [ApplicantStatus.Hired]: GeneralStatus.Active,
  [ApplicantStatus.Rejected]: GeneralStatus.Active
};