import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { OptionChangeEvent } from '@app/shared/fields/autocomplete-field/models';
import { BaseSelectComponent } from '@app/shared/fields/base-select/select.component';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-autocomplete-field',
  templateUrl: 'autocomplete-field.component.html',
  styleUrls: ['autocomplete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteFieldComponent),
      multi: true
    }
  ]
})
export class AutocompleteFieldComponent extends BaseSelectComponent implements ControlValueAccessor, OnInit {
  @Input() minSearchSymbols: number = 0;
  @Input() placeholder: string = 'placeholders.select';
  @Input() searchable: boolean = true;
  @Input() clearable: boolean = true;
  @Input() translateLabel: boolean = false;

  @Output() optionSelect: EventEmitter<OptionChangeEvent> = new EventEmitter<OptionChangeEvent>();

  @ViewChild('inputElement') input: ElementRef<HTMLInputElement>;

  searchText: string = '';
  selectedOption: any;
  isPanelOpened = false;

  private searchSubject: Subject<string> = new Subject<string>();
  private isSearchInputWasChangedByUser = false;

  get isFilledRequiredSymbolsLength(): boolean {
    return this.searchText?.length >= this.minSearchSymbols;
  }

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.searchSubject.pipe(
      tap(() => this.loading = true),
      debounceTime(this.SEARCH_DEBOUNCE_TIME),
      untilDestroyed(this)
    ).subscribe(query => {
      if (!this.isPanelOpened) {
        this.loading = false;
        return;
      }

      this.searchText = query ?? '';
      this.loadOptions();
    });
  }

  protected override loadOptions() {
    super.loadOptions({ search: this.searchText });
  }

  onSearch(query: string): void {
    this.searchText = query ?? '';
    this.isSearchInputWasChangedByUser = true;

    if (this.loadStrategy === 'none' || query.length < this.minSearchSymbols) {
      return;
    }

    this.searchSubject.next(query);
  }

  reset(): void {
    this.selectOption(null);
    this.onSearch('');
  }

  selectOption(value: string | number | null) {
    if (value === this.value) {
      return;
    }

    this.value = value ?? null;
    this.selectedOption = this.findOption(this.value);
    this.searchText = this.selectedOption ? this.translate(this.selectedOption[this.bindLabel]) : '';

    this.onChangeCallback(this.value);
    this.optionSelect.emit({ value, label: this.searchText, optionData: this.selectedOption });
  }

  findOption(value: string | number): any {
    return this.options.find(option => option[this.bindValue] === value);
  }

  findOptionLabel(value: string | number): string {
    const option = this.findOption(value);

    return option ? option[this.bindLabel] : '';
  }


  onFocus(): void {
    if (!this.isFilledRequiredSymbolsLength) {
      return;
    }

    super.onFocus();
  }

  onInputClick(trigger: MatAutocompleteTrigger): void {
    if (!this.searchable) {
      trigger.panelOpen ? trigger.closePanel() : trigger.openPanel();
    }
  }

  onPanelOpen(): void {
    this.isPanelOpened = true;
  }

  onPanelClose(): void {
    this.isPanelOpened = false;
    if (this.selectedOption) {
      this.searchText = this.translate(this.selectedOption[this.bindLabel]);
    }
  }

  protected onOptionsLoad(response: PaginatedResponse) {
    this.options = response.results;

    // Update input text only if options were load silently without changing input manually by user
    if (!this.optionsWereLoadedAtLeastOnce && !this.isSearchInputWasChangedByUser) {
      this.updateInputView();
    }
  }

  protected override updateInputView(): void {
    this.selectedOption = this.findOption(this.value);
    this.searchText = this.selectedOption ? this.translate(this.selectedOption[this.bindLabel]) : '';
    if (this.input) {
      this.input.nativeElement.value = this.searchText;
    }
  }

  private translate(label: string): string {
    return this.translateLabel ? this.translateService.instant(label) : (label ?? '');
  }
}
