import { ChangeDetectionStrategy, Component, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDrag } from '@angular/cdk/drag-drop';

import { CreateNewMessageComponent } from '../create-new-message/create-new-message.component';
import { UserChat } from '../models/chat.model';
import { Message } from '../models/message.model';
import { MessageCenterService } from '../services';
import { cdkOverlayClass } from '../shared/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatComponent } from '@app/pages/message-center/chat/chat.component';
import { ItemsComponent, MergeStrategy } from '@app/core/components';
import { Observable } from 'rxjs';
import { WsData, WsEvent } from '@app/core/services/websocket/ws.models';
import { ResizeEvent } from 'angular-resizable-element/lib/interfaces/resize-event.interface';
import { ChatWindow } from '@app/pages/message-center/chat-windows/models';

const MAX_OPEN_CHATS = 3;

@UntilDestroy()
@Component({
  selector: 'app-chat-windows',
  templateUrl: './chat-windows.component.html',
  styleUrls: ['./chat-windows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWindowsComponent extends ItemsComponent<ChatWindow> implements OnInit {
  @ViewChildren(ChatComponent) private chatComponents: QueryList<ChatComponent>;

  activeChatId: number;

  protected override loadOnInit = false;
  protected override realtimeMergeStrategy = MergeStrategy.Append;
  protected deleteItemRealtimeEvents = [WsEvent.deleteChat];
  protected updateItemRealtimeEvents = [WsEvent.chatListUpdating];
  protected override defaultEventMapper = (data: WsData<any>) => data.payload;

  private chatPositionWhenResizeStart: DOMRect;

  constructor(
    private messageCenterService: MessageCenterService,
    private dialog: MatDialog,
    private renderer: Renderer2,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.messageCenterService.openChat$
      .pipe(untilDestroyed(this))
      .subscribe((chat: UserChat) => this.openNewChatWindow(chat));

    this.messageCenterService.closeChat$
      .pipe(untilDestroyed(this))
      .subscribe((chatId: number) => {
        this.deleteItem({ id: chatId });
      });

    this.messageCenterService.closeAllChats$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.items = [];
        this.cdr.markForCheck();
      });
  }

  openNewMessagePopup(user: UserChat): void {
    this.renderer.addClass(document.body, cdkOverlayClass);

    this.dialog.open(CreateNewMessageComponent, {
      width: '80vw',
      maxWidth: '1222px',
      autoFocus: false,
      data: { ...user }
    })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.renderer.removeClass(document.body, cdkOverlayClass);
      });
  }

  collapseChat(chatId: number): void {
    this.items.find(chat => chat.id === chatId).collapsed = true;
    this.cdr.markForCheck();
  }

  expandChat(chatId: number, cdkDrag: CdkDrag): void {
    const chatIndex =   this.items.findIndex(chat => chat.id === chatId);
    this.items[chatIndex].collapsed = false;
    const messagesComponent = this.chatComponents.get(chatIndex).messagesComponent;
    const unreadMessages = messagesComponent.items.filter(message => !message.is_viewed);
    unreadMessages.forEach((message) => messagesComponent.readMessage(message));
    this.cdr.detectChanges();
    this.adjustBoundaryPosition(cdkDrag);
  }

  private adjustBoundaryPosition(cdkDrag: CdkDrag): void {
    const position: DOMRect = cdkDrag.getRootElement().getBoundingClientRect();
    if (position.top < 0) {
      const freeDragPosition = cdkDrag._dragRef.getFreeDragPosition();
      cdkDrag._dragRef.setFreeDragPosition({ x: freeDragPosition.x, y: freeDragPosition.y - position.top });
    }

    if (position.left < 0) {
      const freeDragPosition = cdkDrag._dragRef.getFreeDragPosition();
      cdkDrag._dragRef.setFreeDragPosition({ x: freeDragPosition.x - position.left, y: freeDragPosition.y });
    }
  }

  onThreadSelect(chat: UserChat, message: Message): void {
    const chatIndex = this.items.findIndex(openChat => chat.id === openChat.id);
    this.items[chatIndex] = { ...this.items[chatIndex], open_thread: message };
    this.cdr.markForCheck();
  }

  onResizeStart(div: HTMLElement): void {
    this.chatPositionWhenResizeStart = div.getBoundingClientRect();
  }

  onResize(resize: ResizeEvent, chat: ChatWindow, ): void {
    this.activeChatId = chat.id;

    const minWidth = 310;
    const minHeight = 200;

    if ((this.chatPositionWhenResizeStart.left + <number>resize.edges.left) > 0) {
      const newWidth = resize.rectangle.right - resize.rectangle.left;
      chat.width = newWidth < minWidth ? minWidth : newWidth;
    }

    if ((this.chatPositionWhenResizeStart.top + <number>resize.edges.top) > 0) {
      const newHeight = resize.rectangle.bottom - resize.rectangle.top;
      chat.height = newHeight < minHeight ? minHeight : newHeight;
    }
  }

  private openNewChatWindow(chat: UserChat): void {
    const isAlreadyOpened = this.items.findIndex(item => item.id === chat.id) !== -1;
    if (isAlreadyOpened) {
      return;
    }

    if (this.items.length >= MAX_OPEN_CHATS) {
      this.deleteItem(this.items[0]);
    }

    this.activeChatId = chat.id;
    this.createItem({
      ...chat,
      collapsed: false,
      index: this.getFreeWindowIndex(),
      width: 318,
      height: 400,
    });
  }

  private getFreeWindowIndex(): number {
    for (let i = 0; i < MAX_OPEN_CHATS; i++) {
      if (this.items.every(chat => chat.index !== i)) {
        return i;
      }
    }
  }

  protected override getItems(params?: any): Observable<ChatWindow[]> {
    return null;
  }
}
