import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

export function toPaginatedResponse<T = any>(): (source$: Observable<any[] | PaginatedResponse>) => Observable<PaginatedResponse<T>> {
  return source$ => source$.pipe(
    map((response) => {
      if (Array.isArray(response)) {
        return {
          results: response,
          count: response.length,
        };
      }

      return response;
    })
  );
}
