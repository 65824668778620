import { User } from './user.model';
import { Message } from './message.model';
import { PaginationParams } from "@app/shared/interfaces/pagination.class";

export interface UserChat {
  id: number;
  name: string;
  image: string;
  unread_messages_ids: number[];
  access_type: number;
  access_type_value: string;
  conversation_type: ConversationType;
  conversation_type_value: string;
  direct_to: Partial<User>;
  last_message?: Partial<LastMessage>;
  owner?: number;
  owner_detail?: Partial<User>;
  members?: User[];
}

export interface GetChatsRequestParams extends PaginationParams {
  search?: string;
  lookup_field?: ChatsLookupField;
}

export enum ChatsLookupField {
  All = 'all',
  Staff = 'staff',
  Caregiver = 'caregiver',
  Chats = 'chats',
}

export enum ConversationType {
  direct = 0,
  group = 1
}

interface LastMessage extends Omit<Message, 'is_new' | 'files' | 'reply_to' | 'replies_info'> {
  has_files: boolean;
}

export interface DirectChatPayload {
  direct_to: number;
  direct_to_meta?: any;
}

export interface GroupChat {
  readonly id: number;
  name: string;
  readonly image: string;
  access_type: number;
  members: User[];
  to_create_members: GroupUser[];
}

interface GroupUser {
  role: number;
  username: string;
  email: string;
  phone: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  avatar: string;
}

export interface GroupChatPayload extends Pick<GroupChat, 'name' | 'image' | 'access_type' | 'members' | 'to_create_members'> {}
