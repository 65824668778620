import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { DefaultRouteReuseStrategy } from './default-route-reuse.strategy';

export interface IRouteReuseStrategyRouterData {
    noReuse?: boolean;
    noReuseBeforePath?: string;
}

@Injectable()
export class RouteNoReuseStrategy extends DefaultRouteReuseStrategy {
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        const currentRouterData = current.data as IRouteReuseStrategyRouterData;
        const futureRouterData = future.data as IRouteReuseStrategyRouterData;

        if (this.isChangeOnlyQueryParams(future, current)) {
            return super.shouldReuseRoute(future, current);
        }

        if (futureRouterData.noReuseBeforePath && getUrlWithoutParams(current).includes(futureRouterData.noReuseBeforePath)) {
            return false;
        }

        if (currentRouterData.noReuse) {
            return false;
        }

        return super.shouldReuseRoute(future, current);
    }

    private isChangeOnlyQueryParams(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
       return getUrlWithoutParams(future) === getUrlWithoutParams(current);
    }
}

function getUrlWithoutParams(route: ActivatedRouteSnapshot): string {
    return (route as any)._routerState.url.split('?')[0];
}
