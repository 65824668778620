export const environment = {
  production: false,
  baseHost: 'test1.jirishealth.com',
  baseProtocol: 'https://',
  baseWsProtocol: 'wss://',
  basePath: '/api/v1/',
  basePort: '8081',
  baseWsPort: '8082',
  baseWsSecret: 'test',

  // Message Center
  mcHost: 'comm.test1.jirishealth.com',
  mcPort: '8081',
  mcWsPort: '8082',
  mcWSSecret: 'test',

  // Google Maps
  googleApiKey: 'AIzaSyAOiE-bWqw9sz30T-L6cMuaEGIFY0JQBm8' //new one 'AIzaSyDMNryAG-nHfIV6BXxyc9DZHlww9yZh48I'
};
