import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../index';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  PatientPOCDuty,
  PlanOfCare,
  POCDuty,
  PreCalculatePOCDuration,
  PreCalculatePOCDurationResponse
} from '@app/models/patient/plan-of-care.model';

@Injectable({
  providedIn: 'root'
})
export class PlanOfCareService {

  constructor(
    private http: HttpService
  ) { }

  getPOCList(params: object): Observable<PaginatedResponse<PlanOfCare>> {
    return this.http.GET<PaginatedResponse<PlanOfCare>>(URLS.poc, params);
  }

  precalculateDurationPOCDuty(data: PreCalculatePOCDuration): Observable<PreCalculatePOCDurationResponse> {
    return this.http.POST<PreCalculatePOCDurationResponse>(URLS.poc_precalculate_duration, data);
  }

  createPOC(data: POCDuty): Observable<POCDuty> {
    return this.http.POST<POCDuty>(URLS.poc, data);
  }

  updatePOC(pocId: number, data: POCDuty): Observable<POCDuty> {
    return this.http.PUT<POCDuty>(`${ URLS.poc }${ pocId }/`, data);
  }

  deletePOC(pocId: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.poc }${ pocId }/`);
  }

  getPOCDutiesById(dutyId: number): Observable<PatientPOCDuty> {
    return this.http.GET<PatientPOCDuty>(`${ URLS.poc }${ dutyId }/`);
  }

  getPOCDutyList(query: string): Observable<PaginatedResponse> {
    return this.http.GET<PaginatedResponse>(`${ URLS.duty }?${ query }`);
  }
}
