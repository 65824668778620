<div class="message">
  <form class="form" autocomplete="off" novalidate [formGroup]="form" (submit)="submit()">

    <div class="message-input-container">
      <input class="text-input input__message"
             [placeholder]="'messageCenter.messageInputPlaceholder' | translate"
             appTrimInput
             formControlName="message"
             autocomplete="off"
             maxlength="1000">

      <app-file-upload type="icon" (filesAdded)="addFiles($event)"></app-file-upload>
    </div>

    <input *ngIf="typeControl.value === MessageType.email"
           class="text-input input__subject"
           type="text"
           appTrimInput
           [placeholder]="'messageCenter.subjectPlaceholder' | translate"
           formControlName="subject"/>

    <ng-select [hidden]="threadMessageId"
               class="message-type-select app-ng-select"
               formControlName="channel"
               #typeControl="ngControl"
               bindLabel="title"
               bindValue="value"
               appendTo="body"
               appearance="outline"
               [dropdownPosition]="'top'"
               [clearable]="false"
               [searchable]="false"
               [items]="messageTypeOptions"
               (change)="changeMessageType($event.value)">
      <ng-template ng-label-tmp ng-option-tmp let-item="item">
        {{ item.title | translate }}
      </ng-template>
    </ng-select>

    <button matRipple
            type="submit"
            class="app-button app-button_blue submit-button"
            [disabled]="!form.valid || isSending"
            (submit)="submit()">
      <mat-spinner *ngIf="isSending" class="spinner" diameter="16" strokeWidth="2"></mat-spinner>
      {{ 'buttons.send' | translate }}
    </button>
  </form>

  <div class="files">
    <div *ngFor="let file of loadedFiles; let fileIndex = index" class="file">
      <mat-icon class="icon-close" (click)="removeAttachedFile(fileIndex)">close</mat-icon>
      <span class="file-name">{{ file.name }}</span>
    </div>
  </div>
</div>
