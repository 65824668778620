import { Permissions, ProfilePermissionsFeatures } from '@app/models/settings/permissions.model';
import { NormalizedContentTypes } from '@app/models/login/login.model';

export interface UserProfile {
  id: number;
  avatar: string;
  full_name: string;
}

export interface UserOfficesDetails {
  id: number;
  name: string;
}

export enum UserType {
  Administrator,
  Coordinator,
  Payroll,
  Biller,
  Caregiver,
  Trainee_Applicant,
  HR,
  Instructor,
  CaseManager,
  ServiceCoordinator
}

export interface AuthProfileInfo {
  id: number;
  companyId: number;
  contentTypes: NormalizedContentTypes;
  permissions: Permissions;
  features: ProfilePermissionsFeatures;
  fullName: string;
  avatar: string;
}
