import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { UsersTeamsService } from '../services/users-teams.service';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { GetUsersTeamsRequestParams, UsersTeam } from '@app/models/users-team.model';
import { UsersTeamStatus } from '@app/core/enums';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

export class UserTeamsOptionsLoader implements OptionsLoader {
  private usersTeamsService = inject(UsersTeamsService);

  getOptions(params?: GetUsersTeamsRequestParams): Observable<PaginatedResponse<UsersTeam>> {
    return this.usersTeamsService.getTeams({
      no_limits: true,
      statuses: UsersTeamStatus.Active,
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
