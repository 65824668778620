import { GeneralStatus } from '@app/core/enums';
import { VisitStatus } from '@app/models/visits/visits.model';
import { StatusesMapper } from '@app/models/statuses-mapper.model';

export const VISIT_STATUSES_MAPPER: StatusesMapper = {
  [VisitStatus.available]: GeneralStatus.Available,
  [VisitStatus.canceled]: GeneralStatus.Cancelled,
  [VisitStatus.offered]: GeneralStatus.Offered,
  [VisitStatus.scheduled]: GeneralStatus.Scheduled,
  [VisitStatus.missed]: GeneralStatus.Missed,
  [VisitStatus.enRoute]: GeneralStatus.EnRoute,
  [VisitStatus.inProgress]: GeneralStatus.InProgress,
  [VisitStatus.completed]: GeneralStatus.Completed
}