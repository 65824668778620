import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PayerInvoiceClaim, PayerInvoiceNestedDetails } from '@app/models/payer/invoicing.model';
import {
  PaymentPayerDetails,
  PaymentPayerDownloadDetails,
  PaymentPayerGenerateInvoices,
  PaymentPayerHistory,
  PaymentPayerInvoice,
  PaymentPayerInvoiceBatch,
  PaymentPayerInvoiceClaim,
  PaymentPayerPrivateClaim,
  PaymentPayerPrivateHistory, PaymentPayerPrivateInvoiceVisit,
  PaymentPayerReport
} from '@app/models/payer/private-pay.model';
import { VisitHistory } from '@app/models/patient/visit-details/history.model';

@Injectable({
  providedIn: 'root'
})
export class PrivatePayService {
  private refreshPage = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpService
  ) { }

  get getRefreshPage(): Observable<boolean> {
    return this.refreshPage.asObservable();
  }

  set setRefreshPage(value: boolean) {
    this.refreshPage.next(value);
  }

  getPaymentPayerInvoiceBatchInfo(batchId: string, query: string): Observable<PaymentPayerInvoiceBatch> {
    return <Observable<PaymentPayerInvoiceBatch>> this.http
    .GET(`${ URLS.payer_invoice_batch }${ batchId }/${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPaymentPayerInvoiceClaim(params?: object): Observable<PaginatedResponse<PaymentPayerInvoiceClaim>> {
    return this.http.GET<PaginatedResponse<PaymentPayerInvoiceClaim>>(URLS.payer_invoice_claim, params);
  }

  getPaymentPayerInvoiceNestedDetails(claimId: number, query: string): Observable<PaymentPayerDetails> {
    return <Observable<PaymentPayerDetails>> this.http
    .GET(`${ URLS.payer_invoice_claim }${ claimId }/${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPaymentPayerPrivateHistory(claimId: number, limit: number = 1000): Observable<PaginatedResponse<PaymentPayerPrivateHistory>> {
    return <Observable<PaginatedResponse<PaymentPayerPrivateHistory>>> this.http
    .GET(`${ URLS.payer_private_payment }?claim_id=${ claimId }&limit=${ limit }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  makePayerPrivatePayment(body): Observable<PaymentPayerPrivateClaim> {
    return <Observable<PaymentPayerPrivateClaim>> this.http
    .POST(URLS.payer_private_payment, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPatientGroupInvoices(claimId: number, query: string): Observable<PaymentPayerInvoice[]> {
    return <Observable<PaymentPayerInvoice[]>> this.http
    .GET(`${ URLS.payer_invoice_claim }${ claimId }/patient_group_invoices/?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  setVoidPayment(paymentId: number, body: { status: number }): Observable<{ status: number }> {
    return <Observable<{ status: number }>> this.http
    .PUT(`${ URLS.payer_private_payment }${ paymentId }/set_void/`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  privatePayGenerateInvoice(claimId: number, query: string, body): Observable<PaymentPayerGenerateInvoices> {
    return <Observable<PaymentPayerGenerateInvoices>> this.http
    .POST(`${ URLS.payer_invoice_claim }${ claimId }/private_pay_generate_invoice/?${ query }`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  changeHistoryPayment(paymentId: number, body: PaymentPayerHistory): Observable<PaymentPayerHistory> {
    return <Observable<PaymentPayerHistory>> this.http
    .PUT(`${ URLS.payer_private_payment }${ paymentId }/`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPrivatePaymentReport(claimId: number, query: string): Observable<PaymentPayerReport> {
    return <Observable<PaymentPayerReport>> this.http
    .GET(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_report/?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPrivatePaymentDownloadInfo(claimId: number, query: string): Observable<PaymentPayerDownloadDetails> {
    return <Observable<PaymentPayerDownloadDetails>> this.http
    .GET(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_download_info/?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  changePrivatePaymentDownloadInfo(claimId: number, query: string, body): Observable<PaymentPayerDownloadDetails> {
    return <Observable<PaymentPayerDownloadDetails>> this.http
    .PUT(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_download_info/?${ query }`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPrivatePaymentHistory(visitId: number, query: string): Observable<PaginatedResponse<VisitHistory>> {
    return <Observable<PaginatedResponse<VisitHistory>>>this.http
    .GET(`${ URLS.history }private_pay_claim/${ visitId }/?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPrivatePaymentInvoices(claimId: number, query: string): Observable<PaymentPayerPrivateInvoiceVisit[]> {
    return <Observable<PaymentPayerPrivateInvoiceVisit[]>>this.http
    .GET(`${ URLS.payer_invoice_claim }${ claimId }/private_payment_invoices/?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
