<div class="message-center">
  <div class="message-center__header">
    <h3 class="message-center__title">{{ 'messageCenter.title' | translate }}</h3>
    <span class="material-icons-two-tone group-icon"
          [matTooltip]="'messageCenter.createGroupChat' | translate"
          (click)="createNewGroup()"
    >group_add</span>

    <button class="app-button app-button_blue btn-close-chats" (click)="closeAllChats()">
      {{ 'messageCenter.closeChats' | translate }}
    </button>

    <button matRipple class="btn-close" (click)="close()">
      <span class="material-icons btn-close__icon">close</span>
    </button>
  </div>

  <app-chats-list (openChat)="openChat($event)"></app-chats-list>
</div>
