import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageFieldComponent),
    multi: true
  }],
})
export class ImageFieldComponent implements ControlValueAccessor {
  @Input() disabled = false;

  value: string;

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  onFileChanged(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (imageData) => {
        this.changeValue(<string>imageData.target.result);
      };
    }
  }

  changeValue(value: string): void {
    this.value = value;
    this.onChangeCallback(this.value);
    this.cdr.markForCheck();
  }

  onBlur(): void {
    this.onTouchedCallback();
    this.cdr.markForCheck();
  }

  writeValue(value: any) {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}

