import {
  Component,
  DoCheck,
  ElementRef,
  ViewChild,
  Input,
  Output,
  OnInit,
  EventEmitter,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef, HostBinding,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgModelBase } from '@app/shared/ng-model.base';
import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import {
  CONTACT_METHOD_CHOICES,
  ETHNICITY_CHOICES,
  GENDER_CHOICES,
  GENDER_PREFERRED_CHOICES,
  GENDER_PREFERRED_TO_WORK_CHOICES,
  RACE_CHOICES,
  USA_STATES_CHOICES,
  USER_STATUSES_CHOICES,
  USER_TYPES_CHOICES,
  PATIENT_TYPE_CHOICES,
  PATIENT_STATUS_CHOICES,
  PATIENT_ADDRESS_TYPE_CHOICES,
  RELATIONSHIP_CHOICES,
  DIAGNOSIS_DATE_TYPE_CHOICES,
  DIAGNOSIS_IDENTIFIED_CHOICES,
  ALLERGY_STATUS_CHOICES,
  PAYER_STATUS_CHOICES,
  PAYER_ICD_CHOICES,
  UNIT_EQUALS_CHOICES,
  RATE_TYPE_CHOICES,
  ROUND_ON_CHOICES,
  OPTION_CHOICES,
  OPTION_TIME_ZONE,
  OFFICE_STATUS_CHOICES,
  PHYSICIAN_TYPES,
  BASE_STATUS_CHOICES,
  MESSAGES_TYPES,
  INTERVIEW_STATUSES,
  APPLICATION_STATUSES,
  YES_NO_CHOICES_BOOLEAN,
  PAY_CYCLE_CHOICES,
  EXPENSES_TYPES_CHOICES,
  ONE_TO_TEN_NUMBERS_CHOICES,
  APPOINTMENT_STATUSES,
  APPOINTMENT_FREQUENCY,
  APPOINTMENT_DOSE_TYPES,
  IN_SERVICE_STATUSES,
  IN_SERVICE_REASONS,
  EDI_PROVIDER_TYPES_CHOICES,
  PAYER_TYPE_CHOICES,
  CLAIM_TYPE_CHOICES,
  EXCLUSION_TYPE_CHOICES,
  MASTERWEEK_PAYER_STATUS_CHOICES,
  MARITAL_STATUS_CHOICES,
  MEASUREMENT_TYPE_CHOICES
} from '@app/shared/constants';

const OPTIONS_TYPE_MAP = {
  userType: USER_TYPES_CHOICES,
  userStatus: USER_STATUSES_CHOICES,
  expenseType: EXPENSES_TYPES_CHOICES,
  exclusionType: EXCLUSION_TYPE_CHOICES,
  oneToTen: ONE_TO_TEN_NUMBERS_CHOICES,
  gender: GENDER_CHOICES,
  genderPreferred: GENDER_PREFERRED_CHOICES,
  genderPreferredToWork: GENDER_PREFERRED_TO_WORK_CHOICES,
  ethnicity: ETHNICITY_CHOICES,
  race: RACE_CHOICES,
  state: USA_STATES_CHOICES,
  contactMethod: CONTACT_METHOD_CHOICES,
  patientType: PATIENT_TYPE_CHOICES,
  patientStatus: PATIENT_STATUS_CHOICES,
  appointmentStatuses: APPOINTMENT_STATUSES,
  appointmentDoseTypes: APPOINTMENT_DOSE_TYPES,
  appointmentFrequency: APPOINTMENT_FREQUENCY,
  patientAddressType: PATIENT_ADDRESS_TYPE_CHOICES,
  relationshipType: RELATIONSHIP_CHOICES,
  diagnosisDateType: DIAGNOSIS_DATE_TYPE_CHOICES,
  diagnosisIdentified: DIAGNOSIS_IDENTIFIED_CHOICES,
  allergyStatus: ALLERGY_STATUS_CHOICES,
  payerStatus: PAYER_STATUS_CHOICES,
  payerIcdCode: PAYER_ICD_CHOICES,
  pricingUnitEquals: UNIT_EQUALS_CHOICES,
  rateType: RATE_TYPE_CHOICES,
  roundOn: ROUND_ON_CHOICES,
  disciplineOption: OPTION_CHOICES,
  // timeZoneOption: OPTION_TIME_ZONE,
  officeOption: OFFICE_STATUS_CHOICES,
  physician: PHYSICIAN_TYPES,
  status: BASE_STATUS_CHOICES,
  masterweekPayerStatus: MASTERWEEK_PAYER_STATUS_CHOICES,
  typeMessage: MESSAGES_TYPES,
  baseOption: BASE_STATUS_CHOICES,
  interviewStatuses: INTERVIEW_STATUSES,
  inServiceStatuses: IN_SERVICE_STATUSES,
  inServiceReasons: IN_SERVICE_REASONS,
  applicationStatus: APPLICATION_STATUSES,
  yesNoChoicesBoolean: YES_NO_CHOICES_BOOLEAN,
  payCycleChoices: PAY_CYCLE_CHOICES,
  maritalStatus: MARITAL_STATUS_CHOICES,
  ediProviderTypes: EDI_PROVIDER_TYPES_CHOICES,
  payerTypes: PAYER_TYPE_CHOICES,
  claimTypes: CLAIM_TYPE_CHOICES,
  measurementTypes: MEASUREMENT_TYPE_CHOICES
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FakeSelectFieldComponent),
  multi: true
};

// DEPRECATED component. Use autocomplete-field.component
@Component({
  selector: 'app-fake-select-field',
  templateUrl: './fake-select-field.component.html',
  styleUrls: ['./fake-select-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FakeSelectFieldComponent extends NgModelBase<string | number> implements OnInit, DoCheck {
  @Input() options: OptionsListItemIfc[] = [];
  @Input() optionsType: string = '';
  @Input() labelHidden: boolean = false;
  @Input() invalid: boolean = false;
  @Input() placeholder: string = 'Select';
  @Input() optionsPosition: 'bottom' | 'top' = 'bottom';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() isDisabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() maxOptionsListHeight: number = 296;

  @Input()
  set updateValueSet(newValue: string | number) {
    this.checkOptions(newValue);
  }

  @Output() selectedEmitter = new EventEmitter<number | string>();

  @ViewChild('label', { static: true }) label: ElementRef;

  @HostBinding('class.readonly') get isReadonly(): boolean {
    return this.readonly;
  }

  optionIndex: number = -1;
  hoverIndex: number = -1;
  touched: boolean = false;
  active: boolean = false;
  optionsAreOpened: boolean = false;
  legendWidth: number = 0;
  private isFirstClick: boolean = false;

  constructor(private eRef: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.options && !this.options.length) {
      this.options = OPTIONS_TYPE_MAP[this.optionsType].map(
        (item) => new OptionsListItemIfc(item.value, item.label)
      );
    }
  }

  ngDoCheck(): void {
    this.cdr.detectChanges();
  }

  checkOptions(newValue): void {
    if (this.options && !this.options.length) {
      this.options = OPTIONS_TYPE_MAP[this.optionsType]?.map(
        (item) => new OptionsListItemIfc(item.value, item.label)
      );
    }

    this.optionIndex = this.options ? this.options.findIndex(
      (item) => item.value === newValue
    ) : -1;
  }

  keyUp(event: KeyboardEvent): void {
    if (this.optionsAreOpened && this.options.length) {
      if (event.keyCode === 40) {
        this.hoverIndex = this.hoverIndex === this.options.length - 1 ? 0 : this.hoverIndex + 1;
      }

      if (event.keyCode === 38) {
        this.hoverIndex = this.hoverIndex === 0 ? this.options.length - 1 : this.hoverIndex - 1;
      }

      if (event.keyCode === 13) {
        this.optionWasSelected(this.hoverIndex);
      }
    }
  }

  closeOptions(): void {
    this.optionsAreOpened = false;
    this.isFirstClick = false;
  }

  focusIn(): void | undefined {
    if (this.isDisabled || this.readonly) {
      return;
    }
    this.active = true;
    this.touched = true;
    this.optionsAreOpened = true;
    this.isFirstClick = true;
    this.hoverIndex = this.optionIndex === -1 ? 0 : this.optionIndex;
  }

  focusOut(): void {
    this.active = false;
    this.closeOptions();
    this.onBlur();
  }

  toggleDropdown(): void {
    if (this.isFirstClick) {
      this.isFirstClick = false;
      return;
    }

    this.optionsAreOpened = !this.optionsAreOpened;
  }

  optionWasSelected(index: number): void {
    this.optionIndex = index;
    this.value = this.options[index].value;
    this.focusOut();
    this.selectedEmitter.emit(this.value);
  }
}
