import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PayerWidget } from '@app/models/widgets.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PayerService } from '@app/core/services/payer/payer.service';
import { GetPayersRequestParams } from '@app/models/payer/payer.model';

@Injectable({
  providedIn: 'root'
})
export class PayersOptionsLoader implements OptionsLoader {
  constructor(private payersService: PayerService) {}

  getOptions(params?: GetPayersRequestParams): Observable<PaginatedResponse<PayerWidget>> {
    return this.payersService.getPayers({
      widget: 'fk',
      lookup_field: 'name',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
