import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { PHONE_MASK } from '@app/shared/constants';
import { EditorInfoCard } from '@app/models/editor-panel.model';
import { CaregiverService, NotificationsService, TranslationData } from '@app/core/services';
import { PinCode, SwitchBlastingResponse } from '@app/models/users/caregiver.model';
import { MessageCenterService } from '@app/pages/message-center/services';
import { UserType } from "@app/models/users/user-profile.model";
import { AllEnums } from '@app/shared/components/status-pill/status-pill.constants';

const PHONE_ICON = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b,.c{fill:#fff;}.b{opacity:0.3;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M19,17.47a14.289,14.289,0,0,1-2.6-.45l-1.19,1.19a14.476,14.476,0,0,0,3.8.75V17.47ZM6.54,5H5.04a15.426,15.426,0,0,0,.75,3.8l1.2-1.2A12.519,12.519,0,0,1,6.54,5Z"/><path class="c" d="M20,21a1,1,0,0,0,1-1V16.51a1,1,0,0,0-1-1,11.407,11.407,0,0,1-3.57-.57.839.839,0,0,0-.31-.05,1.024,1.024,0,0,0-.71.29l-2.2,2.2a15.149,15.149,0,0,1-6.59-6.59l2.2-2.2a1,1,0,0,0,.25-1.02A11.36,11.36,0,0,1,8.5,4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4,17,17,0,0,0,20,21Zm-3.6-3.98a12.753,12.753,0,0,0,2.6.45v1.49a15.426,15.426,0,0,1-3.8-.75ZM5.03,5h1.5a13,13,0,0,0,.46,2.59l-1.2,1.2A14.826,14.826,0,0,1,5.03,5Z"/></svg>';
const EMAIL_ICON = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b,.c{fill:#fff;}.b{opacity:0.3;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M20,8l-8,5L4,8V18H20Zm0-2H4l8,4.99Z"/><path class="c" d="M4,20H20a2.006,2.006,0,0,0,2-2V6a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,6V18A2.006,2.006,0,0,0,4,20ZM20,6l-8,4.99L4,6ZM4,8l8,5,8-5V18H4Z"/></svg>';

@UntilDestroy()
@Component({
  selector: 'app-editor-info-card',
  templateUrl: './editor-info-card.component.html',
  styleUrls: ['./editor-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorInfoCardComponent {
  @Input() idTitle: string = 'userDetails.editMenu_field_userNumber';
  @Input() editorInfoCard: EditorInfoCard;
  @Input() Enum: AllEnums;

  readonly UserType = UserType;
  readonly phoneMask = PHONE_MASK as IMask.MaskedRange;

  isGenerating: boolean;
  isSwitchingBlasting: boolean;

  get showChatIcon(): boolean {
    return [
      UserType.Caregiver,
      UserType.Administrator,
      UserType.Coordinator
    ].includes(this.editorInfoCard.profileType) && !this.editorInfoCard.isPhysician;
  }

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private caregiverService: CaregiverService,
    private messageCenterService: MessageCenterService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    iconRegistry.addSvgIconLiteral('phone', sanitizer.bypassSecurityTrustHtml(PHONE_ICON));
    iconRegistry.addSvgIconLiteral('email', sanitizer.bypassSecurityTrustHtml(EMAIL_ICON));
  }

  refreshPinCode(): void {
    this.isGenerating = true;
    this.caregiverService.generatePinCode(this.editorInfoCard.userId)
      .pipe(
        finalize(() => {
          this.isGenerating = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      ).subscribe((response: PinCode) => {
        this.editorInfoCard.pinCode = response.pin_code;
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  switchReceiveBlasting(checked: boolean): void {
    this.isSwitchingBlasting = true;
    this.caregiverService.switchReceiveBlasting(this.editorInfoCard.userId, checked)
      .pipe(
        finalize(() => {
          this.isSwitchingBlasting = false;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this)
      ).subscribe((response: SwitchBlastingResponse) => {
        this.editorInfoCard.enable_blasting = response.enable_blasting;
        this.notificationsService.showSuccess(
          new TranslationData('userDetails.editMenu_notification_blastingSwitched', { status: this.translate.instant(checked ? 'common.activated' : 'common.deactivated').toLowerCase() })
        );
      }, (error: HttpErrorResponse) => this.notificationsService.showError(error));
  }

  openChat(): void {
    this.messageCenterService.openChatByUserId(this.editorInfoCard.userId)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: err => this.notificationsService.showError(err)
      });
  }
}
