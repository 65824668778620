import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { MIN_DATE } from '@app/common';
import { fromUTCtoDateTime, pad, toIsoDateTime } from '@app/shared/helper';

@Component({
  selector: 'app-date-time-field',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeFieldComponent implements OnInit, OnChanges {
  @Input() initialDateTime: string;
  @Input() dateTooltip: string;
  @Input() editTooltip: string;
  @Output() dateTimeChanged = new EventEmitter<string>();

  form: FormGroup;
  isShowForm: boolean = false;
  amPMList = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' }
  ];

  dateInput: string;
  hoursInput: string;
  minutesInput: string;
  ampmInput: string;

  readonly minDate: Date = MIN_DATE;

  constructor(
    private fb: FormBuilder
  ) { }

  get date(): FormControl {
    return this.form.get('date') as FormControl;
  }
  get hours(): FormControl {
    return this.form.get('hours') as FormControl;
  }
  get minutes(): FormControl {
    return this.form.get('minutes') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.setInitialDateTime();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.initialDateTime.firstChange && changes.initialDateTime.currentValue !== changes.initialDateTime.previousValue) {
      this.initialDateTime = changes.initialDateTime.currentValue;
      this.setInitialDateTime();
    }
  }

  private setInitialDateTime(): void {
    if (!this.initialDateTime) {
      return;
    }

    const dateSplit = fromUTCtoDateTime(this.initialDateTime).split(' ');
    this.dateInput = dateSplit[0];
    const timeSplit = dateSplit[1].split(':');
    this.hoursInput = timeSplit[0];
    this.minutesInput = timeSplit[1];
    this.ampmInput = dateSplit[2];

    this.form.setValue({
      date: this.dateInput,
      hours: this.hoursInput ,
      minutes: this.minutesInput,
      ampm: this.ampmInput
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      date: [null, Validators.required],
      hours: [null, Validators.required],
      minutes: [null, Validators.required],
      ampm: 'AM'
    });
  }

  showForm(): void {
    if (!this.initialDateTime) {
      const dateNow = moment(new Date()).format('MM/DD/YYYY h:mm A');
      const dateSplit = dateNow.split(' ');
      const timeSplit = dateSplit[1].split(':');

      this.form.setValue({
        date: dateSplit[0],
        hours: pad(timeSplit[0]),
        minutes: pad(timeSplit[1]),
        ampm: dateSplit[2]
      });
    }

    this.hours.setValue(pad(+this.hours.value));
    this.minutes.setValue(pad(+this.minutes.value));

    this.isShowForm = true;
  }

  hoursChanged(event): void {
    let value = event.target.value;
    if (value) {
      value = value.replace(/[^0-9]*/g, '');
      this.hours.setValue(value);
    }
    if (!value || +value > 12) {
      this.hours.setErrors({ hours: true });
    } else {
      this.hours.setErrors(null);
    }
  }

  minutesChanged(event): void {
    let value = event.target.value;
    if (value) {
      value = value.replace(/[^0-9]*/g, '');
      this.minutes.setValue(value);
    }
    if (!value || +value > 59) {
      this.minutes.setErrors({ minutes: true });
    } else {
      this.minutes.setErrors(null);
    }
  }

  saveDateTime(): void {
    const form = this.form.value;

    form.hours = pad(+form.hours);
    form.minutes = pad(+form.minutes);

    const date = toIsoDateTime(form.date, `${ form.hours }:${ form.minutes } ${ form.ampm }`);
    this.dateInput = form.date;
    this.hoursInput = form.hours;
    this.minutesInput = form.minutes;
    this.ampmInput = form.ampm;

    this.initialDateTime = date;
    this.dateTimeChanged.emit(date);
    this.isShowForm = false;
  }

  cancel(): void {
    this.initialDateTime = undefined;
    this.form.setValue({
      date: null,
      hours: null,
      minutes: null,
      ampm: 'AM'
    });
    this.dateTimeChanged.emit('');
    this.isShowForm = false;
  }
}
