import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { BasicDisciplineDetail, BasicDisciplineParams } from '@app/models/patient/authorizations.model';
import { DisciplinesService } from '@app/core/services';
import { ObservableCache } from '@app/core/cache';
import { QueryBuilder } from '@app/core/query-builder';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

const cache = new ObservableCache<PaginatedResponse<BasicDisciplineDetail>>();

export class BasicDisciplinesOptionsLoader implements OptionsLoader {
  private disciplinesService = inject(DisciplinesService);

  getOptions(params?: BasicDisciplineParams): Observable<PaginatedResponse<BasicDisciplineDetail>> {
    const paramsKey = QueryBuilder.buildFromObject(params);

    return cache.get(paramsKey, this.disciplinesService.getBaseDisciplines({
        widget: 'fk',
        limit: 100,
        ...(params ?? {})
      }).pipe(toPaginatedResponse())
    );
  }
}
