export interface Rate {
  id: number;
  name: string;
  service: number;
  service_detail: RateServiceDetail;
  service_code: string;
  rate_type: RateType;
  rate_type_value: string;
  regular_rate: string;
  holiday_rate: string;
  round_on: number;
  round_on_value: string;
  option: number;
  option_value: string;
  modifier: string;
  payer: number;
  nurse_service_required: boolean;
  nurse_service: number;
  nurse_service_detail: RateServiceDetail;
  revenue_code?: string;
}

export interface RateServiceDetail {
  id: number;
  name: string;
  is_nurse?: boolean;
}

export enum RateType {
  Hourly,
  Daily,
  Visit,
  Monthly,
}
