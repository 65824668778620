import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../../shared/urls';
import { HttpService } from './http/http.service';
import { ObservableCache } from '../cache';
import { QueryBuilder } from '../query-builder';

type DictionaryType =  'country' | 'medicine' | 'disease';

export interface DictionaryParams {
  dictionary: DictionaryType | DictionaryType[];
  widget?: 'fk';
  pk?: number | string; // search by id
  limit?: number;
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  private cache = new ObservableCache<any>();

  constructor(private http: HttpService) {
  }

  getDictionary<T>(params: DictionaryParams): Observable<T> {
    const url = `${ URLS.dictionary }?${ QueryBuilder.buildFromObject(params) }`;

    return this.cache.get(url, this.http.GET<T>(url));
  }
}
