// Angular
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Installed libraries
import { MatDatepickerModule } from '@angular/material/datepicker';

// Custom things
import { PipesModule } from '@app/shared/pipes/pipes.module';

// Fields
import { FakeSelectFieldComponent } from './fake-select-field/fake-select-field.component';
import { DatepickerFieldComponent } from './datepicker-field/datepicker-field.component';
import { ImageFieldComponent } from './image-field/image-field.component';
import { AddressAutocompleteFieldComponent } from './address-autocomplete-field/address-autocomplete-field.component';
import { ControlErrorsComponent } from './errors/control-errors.component';
import { IMaskModule } from 'angular-imask';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteFieldModule } from '@app/shared/fields/autocomplete-field/autocomplete-field.module';
import {
  PassiveTouchStartModule
} from '@app/shared/directives/passive-touch-start-listener/passive-touch-start.module';
import { CheckboxFieldModule } from '@app/shared/fields/checkbox-field/checkbox-field.module';
import { MultiselectFieldModule } from '@app/shared/fields/multiselect-field/multiselect-field.module';
import {
  CheckboxMultiselectFieldComponent
} from '@app/shared/fields/checkbox-multiselect-field/checkbox-multiselect-field.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    FakeSelectFieldComponent,
    DatepickerFieldComponent,
    ImageFieldComponent,
    AddressAutocompleteFieldComponent,
    ControlErrorsComponent,
    CheckboxMultiselectFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatDatepickerModule,
    RouterModule,
    GooglePlaceModule,
    IMaskModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    AutocompleteFieldModule,
    PassiveTouchStartModule,
    CheckboxFieldModule,
    MultiselectFieldModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  exports: [
    FakeSelectFieldComponent,
    DatepickerFieldComponent,
    ImageFieldComponent,
    AddressAutocompleteFieldComponent,
    ControlErrorsComponent,
    AutocompleteFieldModule,
    MultiselectFieldModule,
    CheckboxFieldModule,
    CheckboxMultiselectFieldComponent,
  ]
})
export class FieldsModule {
}
