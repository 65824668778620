import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef, OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NgModelBase } from '@app/shared/ng-model.base';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxFieldComponent),
  multi: true
};

@UntilDestroy()
@Component({
  selector: 'app-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFieldComponent extends NgModelBase<boolean> implements AfterContentInit, DoCheck, OnInit, OnChanges {
  @Input() setValue: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() placeholder: string = '';
  @Input() size: 'large' | 'medium' | 'small' = 'medium';

  @Output() changeEmitter = new EventEmitter<boolean>();

  @ViewChild('checkbox', { static: false }) checkboxEl: ElementRef;

  active: boolean = false;
  private oldValue: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.innerDisabled$
      .pipe(
        untilDestroyed(this)
      ).subscribe((status: boolean) => {
        this.isDisabled = status;
        this.cdr.detectChanges();
      }
    );

    this.value = this.setValue;
  }

  ngAfterContentInit() {
    this.checkboxEl.nativeElement.checked = !!this.value;
  }

  ngDoCheck() {
    if (this.value !== this.oldValue) {
      this.oldValue = this.value;
      this.cdr.detectChanges();
    }
  }

  changeValue(event: MouseEvent): boolean {
    this.value = !this.value;
    this.changeEmitter.emit(this.value);
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  focusIn(): void {
    this.active = true;
  }

  focusOut(): void {
    this.active = false;
    this.onBlur();
  }

  ngOnChanges(): void {
    this.value = this.setValue;
  }
}
