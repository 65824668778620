import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FormService, NotificationsService, VisitDetailsService } from '@app/core/services';
import { VisitDetailBillingUpdate, VisitDetailsBilling } from '@app/models/patient/visit-details/billing.model';
import { VisitTabFormComponent } from '@app/pages/visit-details/models';
import { VisitStatus } from '@app/models/visits/visits.model';

@UntilDestroy()
@Component({
  selector: 'app-billing-tab',
  templateUrl: './billing-tab.component.html',
  styleUrls: ['./billing-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingTabComponent implements VisitTabFormComponent, OnInit {
  @Input() visitId: number;
  @Input() visitStatus: VisitStatus;

  isLoading: boolean;
  form: FormGroup;
  isSaving: boolean;
  billingInfo: VisitDetailsBilling;

  constructor(
    private fb: FormBuilder,
    private visitDetailsService: VisitDetailsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private formService: FormService,
  ) {
  }

  get isContentEditable(): boolean {
    return !this.billingInfo?.is_billed;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadBillingData();

    this.visitDetailsService.getRefreshCurrentTab
      .pipe(untilDestroyed(this))
      .subscribe((response: boolean) => {
        if (response) {
          this.billingInfo = undefined;
          this.loadBillingData();
        }
      });
  }

  private loadBillingData(): void {
    this.isLoading = true;
    this.cdr.markForCheck();

    this.visitDetailsService.getBilling(this.visitId).pipe(
      finalize(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe((response: VisitDetailsBilling) => {
        this.billingInfo = response;
        this.form.patchValue(response);
      },
      (error: HttpErrorResponse) => {
        this.notificationsService.showError(error);
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      bill_rate: [],
      bill_rate_notes: [],
      mileage_included: this.fb.group({
        active: [false],
        quantity: [],
        rate: [],
      }),
      travel_time: this.fb.group({
        active: [false],
        quantity: [],
        rate: [],
      }),
    });
  }

  private getPayload(): VisitDetailBillingUpdate {
    const payload = this.form.value;
    payload.mileage_included = this.billingInfo.mileage_included_access ? payload.mileage_included : {};
    payload.travel_time = this.billingInfo.travel_time_access ? payload.travel_time : {};

    return payload;
  }

  private patchBillingData(payload: any, control: FormControl): void {
    this.isSaving = true;
    this.cdr.markForCheck();
    
    this.visitDetailsService.patchBilling(this.visitId, payload)
    .pipe(
        finalize(() => {
          this.isSaving = false;
          this.cdr.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe((response) => {
        const { bill_rate, ...res } = response;
        this.form.patchValue(res);
        this.billingInfo = response;
        control.markAsPristine();
        this.notificationsService.showSuccess('visitDetails.tabBilling_action_update-success');
      },
      (error: HttpErrorResponse) => {
        this.formService.nonFieldErrors(error);
      });
  }

  save(onSuccess: () => void = () => null): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.updateBilling(this.visitId, this.getPayload()).pipe(
      finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe({
        next: (response) => {
          this.billingInfo = response;
          this.form.patchValue(response);
          this.form.markAsPristine();
          this.notificationsService.showSuccess('visitDetails.tabBilling_action_update-success');
          onSuccess();
        },
        error: (err) => {
          this.notificationsService.showError(err);
          this.formService.setResponseErrorsToForm(err, this.form);
        }
      }
    );
  }

  saveBillingRate() {
    const val = this.form.value;
    const payload = {
      bill_rate: val.bill_rate 
    };

    this.patchBillingData(payload, this.form.get('bill_rate') as FormControl);
  }

  saveBillingRateNote() {
    const val = this.form.value;
    const payload = {
      bill_rate_notes: val.bill_rate_notes 
    };

    this.patchBillingData(payload, this.form.get('bill_rate_notes') as FormControl);
  }

  saveMileageDistance() {
    const val = this.form.value;
    const payload = {
      mileage_included: val.mileage_included
    };

    this.patchBillingData(payload, this.form.get('mileage_included.quantity') as FormControl);
  }

  saveTravelTimeDuration() {
    const val = this.form.value;
    const payload = {
      travel_time: val.travel_time
    };
    this.patchBillingData(payload, this.form.get('travel_time.quantity') as FormControl);
  }

  getYesNo(value: boolean) {
    return this.translate.instant(`common.${ value ? 'yes' : 'no' }`);
  }
}
