import { PatientWidget, UserWidget } from '@app/models/widgets.model';

export interface ActiveBlastedVisit {
  id: number;
  number: string;
  status: number;
  status_value: string;
  creator: number;
  creator_detail: UserWidget;
  patient: number;
  patient_detail: PatientWidget;
  start_date_time: string;
  end_date_time: string;
  created_at: string;
  offers_count: number;
  new_offers_count: number;
}

export enum BlastingStatus {
  opened = 0,
  completed = 1,
  cancelled = 2
}
