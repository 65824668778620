import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PatientService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { UserWidget } from '@app/models/widgets.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PatientsRequestParams } from '@app/models/patient/patient.model';
import { inject } from '@angular/core';

export class PatientsOptionsLoader implements OptionsLoader {
  private patientsService = inject(PatientService);

  getOptions(params?: PatientsRequestParams): Observable<PaginatedResponse<UserWidget>> {
    return this.patientsService.getPatients({
      lookup_field: 'full_name',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
