import { MatDialog } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData
} from '@app/shared/popups/confirmation-dialog/confirmation-dialog.component';
import 'reflect-metadata';
import { TranslateService } from '@ngx-translate/core';
import { RootInjector } from "@app/root-injector";

interface DoIfConfirmedData extends Omit<ConfirmationDialogData, 'text'> {
  text: ((translate: TranslateService, data?: any) => string) | string;
}

export function DoIfConfirmed(data: DoIfConfirmedData): MethodDecorator {
  return (target: any, methodName: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: any) {
      const dialog: MatDialog = RootInjector.injector.get(MatDialog);
      const translate: TranslateService = RootInjector.injector.get(TranslateService);
      const translateParamIndex = Reflect.getMetadata(getTranslateTextDataStorageKey(methodName), target) ?? 0;

      dialog.open<ConfirmationDialogComponent, ConfirmationDialogData>(ConfirmationDialogComponent, {
        width: ConfirmationDialogComponent.DefaultDialogWidth,
        data: {
          ...data,
          text: typeof data.text === 'function' ? data.text(translate, args[translateParamIndex]) : data.text,
        },
      })
        .afterClosed()
        .pipe(take(1), filter(Boolean))
        .subscribe(() => originalMethod.call(this, ...args));
    };
  };
}

export function TranslateTextData(target: any, key: string, index: number): void {
  Reflect.defineMetadata(getTranslateTextDataStorageKey(key), index, target);
}

const getTranslateTextDataStorageKey = (methodName: string) => `subject-${ methodName }`;
