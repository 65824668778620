import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';
import { getWsData } from '@app/core/services/websocket/rxjs-ws-operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ModuleSubscription, RealtimeService } from '@app/core/services';
import { MCProfile } from '@app/pages/message-center/models/user.model';
import { MessageCenterAuthService } from '@app/pages/message-center/services';
import { UserChat, ConversationType } from '@app/pages/message-center/models/chat.model';
import { User } from '@app/models/message-center/user.model';
import { TypingUserData } from '@app/pages/message-center/typing-users/models';

@UntilDestroy()
@Component({
  selector: 'app-typing-users',
  templateUrl: 'typing-users.component.html',
  styleUrls: ['typing-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypingUsersComponent implements OnInit, OnDestroy {
  @Input() chat: UserChat;

  users: User[] = [];

  private openedWsModule: ModuleSubscription;
  private profile: MCProfile;

  get members(): User[] {
    return this.chat.conversation_type === ConversationType.group ? this.chat.members : [<User>this.chat.direct_to];
  }

  constructor(private realtimeService: RealtimeService,
              private messageCenterAuthService: MessageCenterAuthService,
              private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.profile = this.messageCenterAuthService.getProfile();
    this.subscribeToWebsockets();
  }

  private subscribeToWebsockets(): void {
    this.openedWsModule = this.realtimeService.subscribeToModule({
      user_id: this.profile.id,
      module: WsConnectionModule.chat,
      module_pk: this.chat.id
    });

    this.openedWsModule.moduleMessages$
      .pipe(getWsData<TypingUserData>(), untilDestroyed(this))
      .subscribe((data) => {
        switch (data.event) {
          case WsEvent.user_typing_start:
            this.addTypingUser(data.payload);
            break;
          case WsEvent.user_typing_end:
            this.removeTypingUser(data.payload);
            break;
        }
      });
  }

  private addTypingUser(payload: TypingUserData): void {
    const isExist = this.users.some(user => user.id === payload.id);
    if (!isExist) {
      this.users.push(this.members.find(i => i.id === payload.user_id));
      this.cdr.markForCheck();
    }
  }

  private removeTypingUser(payload: TypingUserData): void {
    this.users = this.users.filter(user => user.id !== payload.user_id);
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.openedWsModule?.unsubscribe();
  }
}
