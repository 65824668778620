import {
  Directive,
  ContentChild,
  AfterViewInit,
  HostListener,
  Input,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core';

import { PanelContentDirective } from './panel-content.directive';

@Directive({
  selector: '[expandablePanel]',
  exportAs: 'appExpandablePanel'
})
export class ExpandablePanelDirective implements AfterViewInit {
  @ContentChild(PanelContentDirective) content: PanelContentDirective;

  @Input() toggleOnClick: boolean = false;
  wasExpandedAtLeastOnce: boolean = false;

  @HostBinding('class.expanded')
  private isExpanded = false;

  @Input() set expanded(value: boolean) {
    this.isExpanded = value;
    if (value) {
      this.wasExpandedAtLeastOnce = true;
    }

    this.content?.onToggle(this.expanded);
  }

  get expanded(): boolean {
    return this.isExpanded;
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.content._init(this.expanded);
  }

  @HostListener('click')
  private onClick(): void {
    if (this.toggleOnClick) {
      this.toggle();
    }
  }

  toggle(): void {
    this.expanded = !this.expanded;
    this.cdr.detectChanges();
    this.content.onToggle(this.expanded);
  }
}
