import { Directive, inject } from '@angular/core';
import { CanComponentDeactivate } from '@app/core/guards/unsaved-changes.guard';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@app/shared/popups/confirmation-dialog/confirmation-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class BaseConfirmationComponent implements CanComponentDeactivate {
  
    protected dialog: MatDialog = inject(MatDialog);

    protected get isSaving(): boolean {
        return false;
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (!this.hasChanges()) {
          return true;
        }
        if (this.isSaving) {
          return false;
        }
          return this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: {
              text: 'patients.leaveConfirmation',
              buttonLabel: 'common.yes',
              buttonClass: 'app-button_blue',
            },
            disableClose: true,
          }).afterClosed().pipe(untilDestroyed(this));
    }

    protected hasChanges(): boolean {
        return false;
    }
}
