import { Directive, inject } from '@angular/core';
import { CanComponentDeactivate } from '@app/core/guards/unsaved-changes.guard';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@app/shared/popups/confirmation-dialog/confirmation-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class BaseEditableComponent implements CanComponentDeactivate {
  
  protected dialog: MatDialog = inject(MatDialog);
  protected isSaving: boolean = false;

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.hasChanges()) {
      return true;
    }
  
    return new Observable<boolean>((observer) => {
      if (this.isSaving) {
        observer.next(false);
        observer.complete();
        return;
      }
  
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          text: 'visitDetails.saveChangesConfirmation',
          buttonLabel: 'buttons.save',
          buttonClass: 'app-button_blue'
        },
        disableClose: true
      });
  
      dialogRef.afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((save) => {
          if (save) {
            this.submit();
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        });
    });
  }

  protected hasChanges(): boolean {
    return false;
  }

  abstract submit(): void;

}
