import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { HttpService } from '../services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { toPaginatedResponse } from '@app/core/rx-operators';

interface Params {
  search?: string;
}

export class DocumentTypesOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  getOptions(params?: Params): Observable<PaginatedResponse> {
    return this.httpService.GET(URLS.document_type, {
      ct: 7,
      widget: 'fk',
      no_limits: 'yes',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
