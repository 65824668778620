<div class="form__item">
  <span class="form__label bold"> {{ 'visitDetails.mileageForm_toggle' | translate }} </span>
  <div class="form__value">
    <mat-slide-toggle #mileageIncludedControl="ngControl"
                      formControlName="active"
                      [disabled]="disabled"
    ></mat-slide-toggle>
  </div>
</div>

<ng-container *ngIf="mileageIncludedControl.value">
  <div class="form__item">
    <span class="form__label"> {{ 'visitDetails.mileageForm_distance' | translate }} </span>
    <div class="form__value" [class.editable-field]="!disabled">
      <app-editable-content (save)="saveDistance()"  #mileageDistanceEditor formControlName="quantity" [disabled]="disabled">
        <input editContent
               class="distance-input"
               type="number"
               [max]="399"
               [formControl]="mileageDistanceEditor.control"
               [placeholder]="'common.mile_short' | translate">
        <div viewContent>{{ 'common.mile_short' | translate }} ({{ mileageDistanceEditor.control.value || 0 }})</div>
      </app-editable-content>
    </div>
  </div>

  <div class="form__item">
    <span class="form__label"> {{ 'visitDetails.mileageForm_rates' | translate }} </span>

    <div class="form__value editable-field" [class.editable-field]="rateEditable && !disabled">
      <app-editable-content #quantityEditor formControlName="rate" [disabled]="!rateEditable || disabled">
        <div editContent>
          $<app-number-field [formControl]="quantityEditor.control"></app-number-field>
        </div>


        <span viewContent>
          {{ (quantityEditor.control.value || 0) | currency }}/{{ 'common.mile_short' | translate }}
        </span>
      </app-editable-content>
    </div>
  </div>

  <div class="form__item amount">
    <span class="form__label"> {{ 'visitDetails.mileageForm_expenseTotal' | translate }} </span>
    <div class="form__value">
      {{ (millageInfo?.total || 0) | currency }}
    </div>
  </div>
</ng-container>
