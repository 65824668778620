<div [style.min-width]="minWidth" class="sized-table-wrapper shadowed-box">
	<div #tableHead class="sized-head">
		<div
			*ngFor="let column of tableFields.fields"
			[style.flex-grow]="column.flex.grow"
			[style.flex-shrink]="column.flex.shrink"
			[style.flex-basis]="column.flex.basis"
			[style.width]="column.width"
			[style.max-width]="column.maxWidth"
			class="sized-column">

			<p>
				<ng-container *ngIf="column.label !== 'checkbox'">
					<span class="header-cell"
                [class.sort-cell]="column.isSorting"
                [ngClass]="sortingColumns[column.name]"
                (click)="onSortClick(column)">

							<span>{{ column.label | translate }}</span>
							<mat-icon class="material-icons icon-sort">north</mat-icon>
					</span>
				</ng-container>

				<ng-container *ngIf="column.label === 'checkbox'">
					<app-checkbox-field
						class="checkbox-field"
						[setValue]="checkboxValue"
						(changeEmitter)="changeCheckboxValue($event)"
					></app-checkbox-field>
				</ng-container>
			</p>
		</div>
	</div>

	<div class="sized-body">
		<div class="pagination-scroll" cdkScrollable (scroll)="onTableVerticalScroll()">
			<div class="scrollable-content">
				<a
					matRipple
					*ngFor="let row of rows"
					[routerLink]="preLink !== null ? [preLink + row.id + postLink] : []"
					[queryParams]="queryParams"
					[hidden]="!rowsAreLoaded"
					class="sized-row data-row"
					(click)="rowClick(row)">

					<div
						*ngFor="let column of tableFields.fields"
						class="sized-column"
						[style.flex-grow]="column.flex.grow"
						[style.flex-shrink]="column.flex.shrink"
						[style.flex-basis]="column.flex.basis"
						[style.width]="column.width"
						[style.max-width]="column.maxWidth">

						<app-avatar
							*ngIf="column.render === 'avatarWithName'"
							[text]="row[column.name].charAt(0)"
							[imageUrl]="row.avatar || ''"
							size="38"
							borderRadius="6">
						</app-avatar>

						<p *ngIf="column.render === 'avatarWithName'">{{row[column.name]}}</p>

						<p *ngIf="column.render === 'phone'">
							<ng-container *ngIf="row.phone_number !== '' && row.phone_number !== undefined">
								{{ row.phone_number | imask:phoneMask }}
							</ng-container>
						</p>

						<p *ngIf="column.render === 'multiplePhones'">
							<ng-container *ngIf="row.phone_numbers.length">
								<span *ngFor="let phone of row.phone_numbers" class="multiple-phones">
									<span *ngIf="phone.number">{{ phone.number | imask:phoneMask }}</span>
								</span>
							</ng-container>
						</p>

						<p *ngIf="column.render === 'fax'">
							<ng-container *ngIf="row[column.name] !== '' && row[column.name] != null">
								{{ row[column.name] | imask:phoneMask }}
							</ng-container>
						</p>

            <p *ngIf="column.render === 'phone2'">
              <ng-container *ngIf="row[column.name]?.number !== '' && row[column.name]?.number != null">
                {{ row[column.name]?.number | imask:phoneMask }}
              </ng-container>
            </p>


            <p *ngIf="column.render === 'default'">{{ row|splitByDot:column.name }}</p>

						<p *ngIf="column.render === 'defaultWithDisabled'"
							[ngClass]="{ 'disabled': row[column.name].disabled }">
							<ng-container *ngIf="row[column.name].value">
								{{ row[column.name].value }}
							</ng-container>
						</p>

						<p *ngIf="column.render === 'offices'">{{ row[column.name] | offices }}</p>

						<p *ngIf="column.render === 'multipleStringData'">{{ row|multipleStringData:column.name }}</p>

						<p *ngIf="column.render === 'status'"
							class="status"
							[ngClass]="{
                'active': row[column.name] === 'Active',
                'in-active': row[column.name] !== 'Active'
              }">
							{{row[column.name]}}
						</p>

						<p *ngIf="column.render === 'yesNo'">{{ row[column.name] ? 'Yes' : 'No' }}</p>

						<p *ngIf="column.render === 'link'" class="link">
							<ng-container *ngTemplateOutlet="linkTpl; context: { $implicit: row }"></ng-container>
						</p>

						<p *ngIf="column.render === 'actions'" class="actions">
							<ng-container *ngTemplateOutlet="itemTpl; context: { $implicit: row }"></ng-container>
						</p>

						<p *ngIf="column.render === 'valueTpl'" class="value-tpl">
							<ng-container *ngTemplateOutlet="itemTpl2; context: { $implicit: row }"></ng-container>
						</p>

						<p *ngIf="column.render === 'customContent'" class="custom-content">
							<ng-container *ngTemplateOutlet="customContent; context: { $implicit: row }"></ng-container>
						</p>

						<!-- Temporary solution for sized table component. Remove when change to mat-table -->
						<p *ngIf="column.render === 'statusPill'">
							<ng-container *ngTemplateOutlet="statusPill; context: { $implicit: row }"></ng-container>
						</p>
					</div>
				</a>

        <app-loading *ngIf="!rowsAreLoaded"></app-loading>

				<div class="sized-row empty-row" [hidden]="!rowsAreLoaded || rows?.length !== 0">
					<div class="sized-column full-width-column">
						<p class="text-center">{{ 'commonNotifications.noResultsFound' | translate }}</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
