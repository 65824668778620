import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { BlastedVisit } from '@app/models/blasting/blasting.model';
import { ActiveBlastedVisit } from '@app/models/blasting/blasting-visit.model';
import { getUtcOffset } from '@app/common';

@Injectable({
  providedIn: 'root'
})
export class ActiveBlastingService {
  constructor(
    private http: HttpService
  ) { }

  getBlastedMasterweeks(params: any): Observable<PaginatedResponse<BlastedVisit>> {
    return this.http.GET(URLS.master_week_blasting, {
    ...(params ?? {}),
        utc_offset: getUtcOffset(),
    });
  }

  getBlastedVisits(params: any): Observable<PaginatedResponse<ActiveBlastedVisit>> {
    return this.http.GET(URLS.visit_blasting, {
      ...(params ?? {}),
      utc_offset: getUtcOffset(),
    });
  }
}
