import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';

import { getFormattedStringByDate, getTomorrow } from '@app/common';
import { DispatchScreenService, FormService, NotificationsService, VisitDetailsService } from '@app/core/services';
import { LoadingHandler } from '@app/core/loading-handler';
import { AssignCaregiverForVisit } from '@app/models/dispatch-screen/dispatch-screen.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { VisitDetailsSchedule, VisitDetailsScheduleUpdate } from '@app/models/patient/visit-details/schedule.model';
import { UserWidget } from '@app/models/widgets.model';
import { selectNotFoundText } from '@app/shared/constants';
import { convertUTCDateTimeTo12Hours, toIsoDateTime } from '@app/shared/helper';
import { VisitStatus } from '@app/models/visits/visits.model';
import { MessageCenterService } from '@app/pages/message-center/services';
import { VisitType } from '@app/models/patient/visit-details/visit.model';
import { VisitTabFormComponent } from '@app/pages/visit-details/models';

@UntilDestroy()
@Component({
  selector: 'app-schedule-tab',
  templateUrl: './schedule-tab.component.html',
  styleUrls: ['./schedule-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleTabComponent implements VisitTabFormComponent, OnInit {
  @Input() visitId: number;

  isSaving: boolean;
  form: FormGroup;
  visitSchedule: VisitDetailsSchedule;
  initialFormState: any;

  caregiver: number = null;
  caregiverList = [];
  caregiversLoading: boolean;

  readonly customNotFoundText = selectNotFoundText;
  readonly visitsTypes = VisitType;
  readonly visitsStatuses = VisitStatus;
  readonly loadingHandler: LoadingHandler = new LoadingHandler();

  constructor(
    private fb: FormBuilder,
    private dispatchScreenService: DispatchScreenService,
    private visitDetailsService: VisitDetailsService,
    private messageCenterService: MessageCenterService,
    private notificationsService: NotificationsService,
    private formService: FormService,
    private cdr: ChangeDetectorRef
  ) {
  }

  get isLoading(): boolean {
    return this.loadingHandler.loading;
  }

  get visitDate(): FormControl {
    return this.form.get('visitDate') as FormControl;
  }

  get scheduledTime(): FormControl {
    return this.form.get('scheduledTime') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadCaregivers();
    this.loadSchedule();

    this.visitDetailsService.getRefreshCurrentTab
      .pipe(untilDestroyed(this))
      .subscribe((response: boolean) => {
        if (response) {
          this.loadSchedule();
        }
      });

    this.visitDetailsService.getUpdatedScheduleTab
      .pipe(untilDestroyed(this))
      .subscribe((response: VisitDetailsSchedule) => {
        if (response) {
          this.visitSchedule = response;
          this.updateForm(response);
        }
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      visitDate: null,
      scheduledTime: null,
      billedTime: null,
      acceptForMasterweek: false,
      mileage_included: false,
      travel_time: false,
      notes: ''
    });
  }

  private loadSchedule(): void {
    const finishLoading = this.loadingHandler.showLoading();

    if (this.visitSchedule) {
      this.visitSchedule = undefined;
      this.cdr.detectChanges();
    }

    this.visitDetailsService.getVisitSchedule(this.visitId).pipe(
      finalize(finishLoading),
      untilDestroyed(this)
    ).subscribe((response: VisitDetailsSchedule) => {
        this.visitSchedule = response;
        this.updateForm(response);
      },
      (error: HttpErrorResponse) => {
        this.formService.nonFieldErrors(error);
      });
  }

  private updateForm(response: VisitDetailsSchedule): void {
    this.form.patchValue({
      visitDate: getFormattedStringByDate(new Date(response.start_date_time)),
      scheduledTime:
        `${ convertUTCDateTimeTo12Hours(response.start_date_time) } - ${ convertUTCDateTimeTo12Hours(response.end_date_time) }`,
      acceptForMasterweek: !response.is_temporary,
      notes: response.notes,
      mileage_included: response.mileage_included,
      travel_time: response.travel_time,
    });
    this.initialFormState = this.form.value;

    this.caregiver = response.caregiver;
    if (response.caregiver) {
      this.caregiverList = [{ value: response.caregiver, label: response.caregiver_detail.full_name }];
    }
    this.cdr.detectChanges();
  }

  timeRangeChange(event: string, formControl: FormControl): void {
    formControl.markAsDirty();
    formControl.setValue(event);

    const form = this.form.value;

    const timeSplit = form.scheduledTime.split(' - ');

    const startDateIso = toIsoDateTime(form.visitDate, timeSplit[0]);
    const endDateIso = toIsoDateTime(form.visitDate, timeSplit[1]);

    const payload = {
      start_date_time: startDateIso,
      end_date_time: this.isStartDateBiggerThenEndDate(startDateIso, endDateIso) ? this.addOneDayToDate(endDateIso) : endDateIso,
    };

    this.patchScheduleData(payload, this.scheduledTime);
  }

  notesSaved() {
    const form = this.form.value;
    const payload = {
      notes: form.notes
    }
    this.patchScheduleData(payload, this.form.get('notes') as FormControl)
  }

  private loadCaregivers(search: string = ''): void {
    this.caregiversLoading = true;
    this.dispatchScreenService.getAvailableCaregiversByVisitId(this.visitId, search).pipe(
      finalize(() => {
        this.caregiversLoading = false;
        this.cdr.detectChanges();
      }),
      untilDestroyed(this)
    ).subscribe((response: PaginatedResponse<UserWidget>) => {
        this.caregiverList = response.results.map(caregiver => ({ value: caregiver.id, label: caregiver.full_name }));
      }, error => {
        this.notificationsService.showError(error);
      });
  }

  private getPayloadForSave(): VisitDetailsScheduleUpdate {
    const form = this.form.value;
    const timeSplit = form.scheduledTime.split(' - ');
    const startDateIso = toIsoDateTime(form.visitDate, timeSplit[0]);
    const endDateIso = toIsoDateTime(form.visitDate, timeSplit[1]);

    return {
      is_temporary: !form.acceptForMasterweek,
      start_date_time: startDateIso,
      end_date_time: this.isStartDateBiggerThenEndDate(startDateIso, endDateIso) ? this.addOneDayToDate(endDateIso) : endDateIso,
      mileage_included: form.mileage_included,
      travel_time: form.travel_time,
      notes: form.notes
    };
  }

  private isStartDateBiggerThenEndDate(startDateIso: string, endDateIso: string): boolean {
    return new Date(startDateIso).getTime() > new Date(endDateIso).getTime();
  }

  private addOneDayToDate(isoDate: string): string {
    const endDate: Date = getTomorrow(new Date(isoDate));

    return endDate.toISOString();
  }

  private patchScheduleData(payload: any, control: FormControl): void {
    this.isSaving = true;
    this.cdr.markForCheck();
    
    this.visitDetailsService.patchVisitSchedule(this.visitId, payload)
      .pipe(finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }), untilDestroyed((this)))
      .subscribe((response: VisitDetailsSchedule) => {
        control.markAsPristine();
        this.notificationsService.showSuccess('visitDetails.tabVisit_action_update-success');
      },
      (error: HttpErrorResponse) => {
        this.formService.nonFieldErrors(error);
      });
  }

  save(onSuccess: () => void = () => null): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.updateVisitSchedule(this.visitId, this.getPayloadForSave()).pipe(
      finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe((response: VisitDetailsSchedule) => {
        this.visitSchedule = response;
        this.updateForm(response);
        this.form.markAsPristine();
        this.notificationsService.showSuccess('visitDetails.tabVisit_action_update-success');
        onSuccess();
      },
      (error: HttpErrorResponse) => {
        this.formService.nonFieldErrors(error);
      });
  }

  searchCaregiver(search: { term: string, items: any[] }): void {
    const searchVal = search.term.trim();
    if (!searchVal) {
      this.removeCaregiver();
    } else {
      this.loadCaregivers(searchVal);
    }
  }

  changeCaregiver(caregiver: { value: number, label: string }): void {
    if (caregiver) {
      this.dispatchScreenService.assignCaregiverForVisit(this.visitId, { caregiver: caregiver.value })
        .pipe(untilDestroyed(this))
        .subscribe({
          error: (error: HttpErrorResponse) => this.formService.nonFieldErrors(error),
        });
    }
  }

  removeCaregiver(): void {
    this.dispatchScreenService.assignCaregiverForVisit(this.visitId, { caregiver: null })
      .pipe(untilDestroyed(this))
      .subscribe((response: AssignCaregiverForVisit) => {
        this.loadCaregivers();
      });
  }

  openChat(): void {
    this.messageCenterService.openChatByUserId(this.caregiver)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error: HttpErrorResponse) => this.notificationsService.showError(error),
      });
  }
}
