import { NgModule } from '@angular/core';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element';

import { MessageCenterComponent } from './message-center.component';
import { SharedModule as MessageSharedModule } from './shared/shared.module';
import { CreateGroupComponent } from './create-group/create-group.component';
import { LoadingModule } from '@app/shared/components/loading/loading.module';
import { ChatsListComponent } from '@app/pages/message-center/chats-list/chats-list.component';
import { MessagesComponent } from '@app/pages/message-center/messages-list/messages.component';
import { SendMessageFormComponent } from '@app/pages/message-center/send-message-form/send-message-form.component';
import { ChatHeaderComponent } from '@app/pages/message-center/chat-header/chat-header.component';
import { ChatComponent } from '@app/pages/message-center/chat/chat.component';
import { ChatMessageComponent } from '@app/pages/message-center/chat-message/chat-message.component';
import { ViewImageComponent } from '@app/pages/message-center/view-image/view-image.component';
import { ChatWindowsComponent } from '@app/pages/message-center/chat-windows/chat-windows.component';
import { CreateNewMessageComponent } from '@app/pages/message-center/create-new-message/create-new-message.component';
import { FormControlNameExport } from '@app/shared/directives/export-control.directive';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { TypingUsersComponent } from '@app/pages/message-center/typing-users/typing-users.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { FieldsModule } from "@app/shared/fields";

@NgModule({
  declarations: [
    MessageCenterComponent,
    CreateGroupComponent,
    ChatsListComponent,
    MessagesComponent,
    SendMessageFormComponent,
    ChatHeaderComponent,
    ChatComponent,
    ViewImageComponent,
    CreateNewMessageComponent,
    ChatWindowsComponent,
    TypingUsersComponent,
    ChatMessageComponent
  ],
  imports: [
    MessageSharedModule,
    FormControlNameExport,
    LoadingModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    PipesModule,
    DragDropModule,
    ResizableModule,
    MatTooltipModule,
    FieldsModule,
  ],
  exports: [
    MessageCenterComponent,
    ChatComponent,
    ChatsListComponent,
    ChatWindowsComponent,
    CreateGroupComponent,
  ],
})
export class MessageCenterModule {
}
