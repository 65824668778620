import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import {
  AuthorizationDetails, AuthPayer,
  AuthStatus,
  AvailableNurseAuth, GetAuthorizationsRequestParams,
  NurseServiceShouldBeCreated,
  NurseVisitShouldBeCreated,
  PatientAvailableAuth,
  ServiceCalculatedHours,
  ServiceDiscipline,
  ServiceItemCalculation
} from '@app/models/patient/authorizations.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsService {

  constructor(
    private http: HttpService
  ) { }

  getAuthorizations(params?: GetAuthorizationsRequestParams): Observable<PaginatedResponse<AuthorizationDetails>> {
    return this.http.GET<PaginatedResponse<AuthorizationDetails>>(URLS.payer_authorization, params);
  }

  uploadAuthorizationFile(payload): Observable<AuthorizationDetails> {
    return <Observable<AuthorizationDetails>>this.http
    .POST(`${ URLS.payer_authorization }`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  updateAuthorizationById(authId: number, payload): Observable<AuthorizationDetails> {
    return <Observable<AuthorizationDetails>>this.http
    .PUT(`${ URLS.payer_authorization }${ authId }/`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  calculateServiceHours(data: ServiceCalculatedHours, isNurse: boolean = false): Observable<ServiceItemCalculation> {
    let url = `${ URLS.payer_service_calculate_hours }`;
    if (isNurse) {
      url += '?is_nurse=yes';
    }
    return <Observable<ServiceItemCalculation>>this.http
    .POST(url, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getServicesList(query: string): Observable<ServiceDiscipline[]> {
    return <Observable<ServiceDiscipline[]>>this.http
    .GET(`${ URLS.payer_pricing_discipline }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  checkNurseVisitShouldBeCreated(visitId: number): Observable<NurseVisitShouldBeCreated> {
    return <Observable<NurseVisitShouldBeCreated>>this.http
    .GET(`${ URLS.payer_authorization }${ visitId }/nurse_visit_should_be_created/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  checkNurseServiceShouldBeCreated(payload: NurseServiceShouldBeCreated): Observable<NurseServiceShouldBeCreated> {
    return <Observable<NurseServiceShouldBeCreated>>this.http
    .POST(`${ URLS.payer_authorization }nurse_service_should_be_added/`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getAvailableAuth(query: string): Observable<PatientAvailableAuth[]> {
    return <Observable<PatientAvailableAuth[]>>this.http
    .GET(`${ URLS.payer_authorization }available/?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  switchAuthStatus(authId: number, payload: { status: number }): Observable<AuthStatus> {
    return <Observable<AuthStatus>>this.http
    .PATCH(`${ URLS.payer_authorization }${ authId }/change_status/`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getRnAuthList(query: string): Observable<AvailableNurseAuth[]> {
    return <Observable<AvailableNurseAuth[]>>this.http
    .GET(`${ URLS.available_nurse_authorizations }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getAuthPayersList(query: string): Observable<AuthPayer[]> {
    return <Observable<AuthPayer[]>>this.http
    .GET(`${ URLS.payer }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
