<div class="history">
  <div class="history__form table-search">
    <div class="app-date-range">
      <mat-form-field class="picker-field" appearance="fill">
        <mat-date-range-input [rangePicker]="picker">
          <input
            #dateRangeStart
            matStartDate
            readonly
            placeholder="{{ 'visitDetails.tabHistory_filters_search_fromDate' | translate }}"
            [formControl]="startDatePicker"
            (click)="picker.open()">
          <input
            #dateRangeEnd
            matEndDate
            readonly
            placeholder="{{ 'visitDetails.tabHistory_filters_search_toDate' | translate }}"
            [formControl]="endDatePicker"
            (click)="picker.open()"
            (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
        </mat-date-range-input>
        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-icon *ngIf="rangeDate.end" (click)="resetTimeRange()">close</mat-icon>
      </mat-form-field>
    </div>

   <div>
     <app-multiselect-field
       bindLabel="label"
       bindValue="value"
       size="small"
       [viewInputPrefix]="'visitDetails.tabHistory_select_type_label' | translate"
       [autoselectAllAfterFirstLoad]="true"
       [showToggleAllOption]="true"
       [showValueAsCounter]="true"
       [optionsLoader]="typesOptionsLoader"
       [loadStrategy]="'onInit'"
       [translateLabel]="true"
       (optionSelect)="changeTypes($event.newValues)"
     ></app-multiselect-field>
   </div>
  </div>

  <div *ngIf="historyList.length" class="history__table">
    <mat-table [dataSource]="historyList">

      <ng-container matColumnDef="changes">
        <mat-header-cell *matHeaderCellDef>{{ 'visitDetails.tabHistory_table_column_changes' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ row.change_type_value }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef>{{ 'visitDetails.tabHistory_table_column_description' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ row.message }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>{{ 'visitDetails.tabHistory_table_column_date' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ convertDateTime(row.change_date_time) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="author">
        <mat-header-cell *matHeaderCellDef>{{ 'visitDetails.tabHistory_table_column_author' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ row.user_short_name }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

  <app-loading *ngIf="isLoading" class="loading"></app-loading>

  <app-no-results *ngIf="!isLoading && !historyList?.length"></app-no-results>

  <app-paginator
    [hidden]="isLoading"
    [total]="pagination.total"
    [setPageNumber]="pagination.pageNumberDetector"
    (paginatorConfigChanged)="loadHistory()"
    (offsetWasChanged)="pagination.offsetChanged($event);"
    (limitWasChanged)="pagination.limitChanged($event);">
  </app-paginator>
</div>
