import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { IdNameWidgetResponse } from '@app/models/response.model';
import { HttpService } from '../services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';
import { ObservableCache } from '@app/core/cache';
import { QueryBuilder } from '@app/core/query-builder';
import { toPaginatedResponse } from '@app/core/rx-operators';

interface Params {
  search?: string;
}

const cache = new ObservableCache<PaginatedResponse<IdNameWidgetResponse>>();

export class ReactionsOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  getOptions(params?: Params): Observable<PaginatedResponse<IdNameWidgetResponse>> {
    const paramsKey = QueryBuilder.buildFromObject(params);

    return cache.get(paramsKey, this.httpService.GET(URLS.reaction, {
        widget: 'fk',
        ...(params ?? {})
      }).pipe(toPaginatedResponse())
    );
  }
}
