import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { SizedTableField, SizedTableFieldsIfc } from '@app/shared/interfaces/sized-table-fields';
import { PHONE_MASK } from '@app/shared/constants';

@Component({
  selector: 'app-sized-table',
  templateUrl: './sized-table.component.html',
  styleUrls: ['./sized-table.component.scss']
})
export class SizedTableComponent implements OnInit {
  phoneMask = PHONE_MASK as IMask.MaskedRange;

  @ViewChild('tableHead') tableHead;
  @ViewChild(CdkScrollable) tableContainer: CdkScrollable;

  @Input() rows: any[] = [];
  @Input() tableFields: SizedTableFieldsIfc;
  @Input() preLink: string | null = '';
  @Input() postLink: string = '';
  @Input() queryParams: object = {};
  @Input() rowsAreLoaded: boolean = false;
  @Input() minWidth: string = '800px';
  @Input() checkboxValue: boolean = false;
  @Input() initialSort: { field: string, asc: boolean };
  @Input() linkTpl: TemplateRef<any>;
  @Input() itemTpl: TemplateRef<any>;
  @Input() itemTpl2: TemplateRef<any>;
  @Input() customContent: TemplateRef<any>;
  @Input() statusPill: TemplateRef<any>;

  @Output() rowClicked = new EventEmitter<any>();
  @Output() checkboxChanged = new EventEmitter<boolean>();
  @Output() sortingChanged = new EventEmitter<{ field: string, asc: boolean }>();
  @Output() tableVerticalScroll = new EventEmitter<number>();

  sortBy: { field: string, asc: boolean };
  sortingColumns = {};

  ngOnInit(): void {
    this.tableFields.fields.forEach(col => {
      this.sortingColumns[col.name] = 'noSort';
    });
    if (this.initialSort) {
      this.sortBy = this.initialSort;
      this.sortingColumns[this.initialSort.field] = this.initialSort.asc ? 'sortAsc' : 'sortDesc';
    }
  }

  onTableVerticalScroll(): void {
    this.tableVerticalScroll.emit(this.tableContainer.getElementRef().nativeElement.scrollTop);
  }

  rowClick(row): void {
    this.rowClicked.emit(row);
  }

  changeCheckboxValue(value: boolean) {
    this.checkboxChanged.emit(value);
  }

  onSortClick(column: SizedTableField): void {
    if (!column.isSorting) {
      return;
    }

    this.clearSortingColumns();
    const columnName = column.name;
    if (columnName !== this.sortBy?.field) {
      this.sortingColumns[columnName] = 'sortAsc';
      this.sortBy = { field: columnName, asc: true };
    } else {
      if (this.sortBy.asc) {
        this.sortingColumns[columnName] = 'sortDesc';
        this.sortBy = { field: columnName, asc: false };
      } else {
        this.sortingColumns[columnName] = 'noSort';
        this.sortBy = undefined;
      }
    }
    this.sortingChanged.emit(this.sortBy);
  }

  private clearSortingColumns(): void {
    for (let key in this.sortingColumns) {
      if (this.sortingColumns.hasOwnProperty(key)) {
        this.sortingColumns[key] = 'noSort';
      }
    }
  }
}
