import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { Discipline, GetDisciplinesRequestParams } from '@app/models/settings/disciplines.model';
import { BasicDisciplineDetail, BasicDisciplineParams } from '@app/models/patient/authorizations.model';

@Injectable({
  providedIn: 'root'
})
export class DisciplinesService {

  constructor(
    private http: HttpService
  ) { }

  getBaseDisciplines(params?: BasicDisciplineParams): Observable<PaginatedResponse<BasicDisciplineDetail>> {
    return this.http.GET<PaginatedResponse<BasicDisciplineDetail>>(`${ URLS.basic_discipline }`, params);
  }

  getDisciplines(params: GetDisciplinesRequestParams): Observable<PaginatedResponse<Discipline>> {
    return this.http.GET<PaginatedResponse<Discipline>>(URLS.discipline, params);
  }

  addDiscipline(discipline: Discipline): Observable<Discipline> {
    return this.http.POST<Discipline>(`${ URLS.discipline }`, discipline);
  }

  editDiscipline(discipline: Discipline): Observable<Discipline> {
    return this.http.PUT<Discipline>(`${ URLS.discipline }${ discipline.id }/`, discipline);
  }

  deleteDiscipline(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.discipline }${ id }/`);
  }
}
