import { DatePipe } from '@angular/common';

import { environment } from 'environments/environment';
import { DISCHARGED_STATUS_CHOICES } from '@app/shared/constants';
import { UserStatus } from '@app/models/patient/edit-patient-data.model';
import { FileLike, FileURL } from '@app/shared/components/upload-button/upload-button.constants';
import { QueryBuilder } from '@app/core/query-builder';

export const PageSizeOptions = [15, 30, 45, 60, 100];
export const PageSize = PageSizeOptions[1];

export enum billingStatuses {
  new = 0,
  ready = 1,
  submitted = 2,
  accepted = 3,
  rejected = 4,
  approved = 5,
  denied = 6,
  pended = 7,
  voided = 8,
  paid = 9,
  partialPaid = 10,
  notBilled = 11,
  billed = 12,
  open = 13,
  closed = 14,
  incomplete = 15,
  completed = 16,
  notPaid = 17
}

export enum LoadDataTrigger {
  Initial,
  Sort,
  Scroll,
  Search
}

export enum SchedulingStatisticFieldsEnum {
  TotalVisits = 'total_visits',
  TotalHours = 'total_hours',
  TotalUnits = 'total_units',
  Patients = 'patients',
  AssignedVisits = 'assigned_visits',
  UnassignedVisits = 'unassigned_visits',
  CancelledVisits = 'cancelled_visits',
  MissedVisits = 'missed_visits',
  OvertimeVisits = 'overtime_visits',
  AssignedVisitsHours = 'assigned_visits_hours',
  CancelledVisitsHours = 'cancelled_visits_hours',
  MissedVisitsHours = 'missed_visits_hours'
}

export const ALLOWED_STATISTIC_FILTERS = {
  [SchedulingStatisticFieldsEnum.TotalVisits]: false,
  [SchedulingStatisticFieldsEnum.TotalHours]: false,
  [SchedulingStatisticFieldsEnum.TotalUnits]: false,
  [SchedulingStatisticFieldsEnum.Patients]: false,
  [SchedulingStatisticFieldsEnum.AssignedVisitsHours]: false,
  [SchedulingStatisticFieldsEnum.CancelledVisitsHours]: false,
  [SchedulingStatisticFieldsEnum.MissedVisitsHours]: false,
  [SchedulingStatisticFieldsEnum.UnassignedVisits]: true,
  [SchedulingStatisticFieldsEnum.AssignedVisits]: true,
  [SchedulingStatisticFieldsEnum.CancelledVisits]: true,
  [SchedulingStatisticFieldsEnum.MissedVisits]: true,
  [SchedulingStatisticFieldsEnum.OvertimeVisits]: true
};

export enum batchReportComponents {
  mainBatch = 'main-batch',
  payrollBatch = 'payroll-batch',
  ediBatch = 'edi-batch'
}

export enum rnVisitTypes {
  assessment = 2,
  supVisit = 1
}

export enum rnVisitPaymentTypes {
  billable = 0,
  nonBillable = 1
}

export enum CalendarArrowAction {
  Add = 'add',
  Subtract = 'subtract',
  Select = 'select'
}

const DATE_PIPE = new DatePipe('en');
export const USER_NAME_REGEXP = /^[\p{Alpha}]*[\p{Alpha}-]*[\p{Alpha}]+$/u;

export function unsubscribe(...subscriptions) {
  subscriptions.forEach(
    (subscription, index, array) => {
      if (subscription && subscription.unsubscribe && typeof array[index].unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    }
  );
}

export function pad(n: string | number): string {
  return (+n < 10 ? '0' : '') + n;
}

export function fromISOtoDate(dateIso: string, isTime: boolean = false): string {
  if (!dateIso) {
    return '';
  }
  const date = new Date(dateIso);
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1);
  const day = pad(date.getUTCDate());
  const utcHours = date.getUTCHours();
  const hours = pad((utcHours % 12) || 12);
  const minutes = pad(date.getUTCMinutes());
  const ampm = utcHours < 12 ? 'AM' : 'PM';
  let dateStr = `${ month }/${ day }/${ year }`;
  if (isTime) {
    dateStr += ` ${ hours }:${ minutes } ${ ampm }`;
  }
  return dateStr;
}

export function dateToString(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${ month }/${ day }/${ year }`;
}

export function toIsoDateTime(date: string, time: string): string {
  if (!date || !time) {
    return '';
  }
  return new Date(`${ date } ${ time }`).toISOString();
}

export function convertTimeToUTC(time: string): string | null {
  if (!time) {
    return null;
  }

  const format24Split = convertFrom12To24Format(time).split(':');
  const date = new Date(new Date().setUTCHours(+format24Split[0], +format24Split[1], 0));

  return DATE_PIPE.transform(date, 'hh:mm a');
}

export function convertUTCDateTimeTo12Hours(dateTime: string): string {
  if (!dateTime) {
    return '';
  }

  return DATE_PIPE.transform(dateTime, 'hh:mm a');
}

export function convertUTCDateTimeToDate(dateTime: string | Date): string {
  if (!dateTime) {
    return '';
  }

  return DATE_PIPE.transform(dateTime, 'MM/dd/YYYY');
}

export function fromUTCtoDateTime(date: string | Date): string {
  if (!date) {
    return '';
  }

  return DATE_PIPE.transform(date, 'MM/dd/YYYY hh:mm a');
}

export function toISODate(date: string): string {
  if (!date) {
    return '';
  }
  return new Date(date.replace(/-/g, '/')).toISOString();
}

export function convertTimeFormatTo12Hour(isoTime: string): string {
  let hours = parseInt(isoTime.substring(0, 2), 10);
  let minutes = isoTime.substring(3, 5);
  let ampm = 'AM';
  if (hours === 12) {
    ampm = 'PM';
  } else if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
    ampm = 'PM';
  }
  return `${ hours }:${ minutes } ${ ampm }`;
}

export function convertFrom12To24Format(time12: string): string {
  const [sHours, minutes, period] = time12.match(/([0-9]{1,2}):([0-9]{1,2}) (AM|PM)/).slice(1);
  const PM = period === 'PM';
  const hours = (+sHours % 12) + (PM ? 12 : 0);

  return `${ ('0' + hours).slice(-2) }:${ minutes }`;
}

export enum InterviewStatusName {
  Scheduled = 'scheduled',
  Canceled = 'canceled',
  NoShow = 'no show',
  Complete = 'complete'
}

export function setInterviewStyleClass(status: string): string {
  return {
    [InterviewStatusName.Scheduled]: 'scheduled',
    [InterviewStatusName.Canceled]: 'canceled',
    [InterviewStatusName.NoShow]: 'no-show',
    [InterviewStatusName.Complete]: 'complete',
  }[status.toLowerCase()] ?? '';
}

export function convertHoursMinutes(num: number): string {
  const hours = Math.floor(num / 60);
  const h = hours > 0 ? `${ hours } h` : '';
  const minutes = num % 60;
  const min = minutes > 0 ? `${ minutes } min` : '';
  return `${ h } ${ min }`;
}

export function getFileName(url: string): string {
  if (!url) {
    return '';
  }
  const startIndex = url.lastIndexOf('/');
  const endIndex = url.lastIndexOf('?');
  return endIndex !== -1 ? url.slice(startIndex + 1, endIndex) : url.slice(startIndex + 1);
}

export function isNumber(value): boolean {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function getNumberOfWeeks(startDate: string, endDate: string): number {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  return Math.ceil((end - start) / (7 * 24 * 60 * 60 * 1000));
}

export function stringTransformCapitalize(str: string): string {
  function transformStr(s) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  let wholeStr = '';
  if (str) {
    const splitStr = str.split('-');
    splitStr.forEach((el, i) => {
      wholeStr += transformStr(el);
      if (i + 1 < splitStr.length) {
        wholeStr += '-';
      }
    });
  }
  return wholeStr;
}

export function setStatusColor(status: number): string {
  let statusClass = '';
  switch (status) {
    case 0: statusClass = 'available'; break;
    case 1: statusClass = 'canceled'; break;
    case 2: statusClass = 'offered'; break;
    case 3: statusClass = 'requested'; break;
    case 4: statusClass = 'scheduled'; break;
    case 5: statusClass = 'missed'; break;
    case 6: statusClass = 'en-route'; break;
    case 7: statusClass = 'no-show'; break;
    case 8: statusClass = 'in-progress'; break;
    case 9: statusClass = 'completed'; break;
  }
  return statusClass;
}

export const cloneDeep = <T>(data: T): T => JSON.parse(JSON.stringify(data));

export function flattenObject(obj: any): any {
  function traverseAndFlatten(currentNode: any, target: any, flattenedKey?: string) {
    for (const key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        let newKey;
        if (flattenedKey === undefined) {
          newKey = key;
        } else {
          newKey = flattenedKey + '.' + key;
        }

        const value = currentNode[key];
        if (typeof value === 'object') {
          traverseAndFlatten(value, target, newKey);
        } else {
          target[newKey] = value;
        }
      }
    }
  }

  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);

  return flattenedObject;
}

export function downloadFile(fileUrl: string): void {
  const fullUrl = `${ environment.baseProtocol }${ environment.baseHost }:${ environment.basePort }${ fileUrl }`;
  window.open(fullUrl, '_blank');
}

export function openInNewTab(url: string, params?: object): void {
  if (params) {
    url += `?${ QueryBuilder.buildFromObject(params) }`;
  }

  window.open(url, '_blank');
}

export function print(content: string): void {
  const printWindow = window.open('', '', 'width=800,height=600');

  if (printWindow) {
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  }
}

export const isNullOrUndefined = <T>(item: T): item is null | undefined => item === null || item === undefined;

export function isFileLikeArray(value: unknown): value is FileLike[] {
  return value !== null && Array.isArray(value) && value.every((item) => item instanceof File || isFileURL(item));
}

export function isFileURL(value: unknown): value is FileURL {
  return value !== null && typeof value === 'object' && 'url' in value && 'name' in value && 'id' in value;
}

export function megabytesToBytes(MBs: number): number {
  const oneMegabyteInBytes = Math.pow(1024, 2);

  return MBs * oneMegabyteInBytes;
}

export function isUserStatusDischarged(status: UserStatus): boolean {
  return DISCHARGED_STATUS_CHOICES.some(({ value }) => value === status);
}

export function getRemainingScrollDistanceToBottom(scrollContainer: HTMLElement): number {
  return scrollContainer.scrollHeight - (Math.abs(scrollContainer.scrollTop) + scrollContainer.clientHeight);
}
