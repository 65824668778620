<div *ngIf="filteredAttentions?.length" class="wrapper">
  <div *ngFor="let attention of filteredAttentions" class="attention {{ attentionMap[attention].className || '' }}">
    <svg-icon class="icon"
              src="assets/icons/visit-attentions/{{ attentionMap[attention].icon }}"
              [matTooltip]="attentionMap[attention].text | translate"
              [matTooltipDisabled]="!showDescriptionByTooltip"
              [svgStyle]="{ width: iconSize, height: iconSize }"
    ></svg-icon>

    <span class="message" *ngIf="!showDescriptionByTooltip"> {{ attentionMap[attention].text | translate }} </span>
  </div>
</div>
