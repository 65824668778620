import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Message } from '../models/message.model';
import { ISOTimeToDaysHoursMinutes } from '../shared/helper';
import { ViewImageComponent } from '@app/pages/message-center/view-image/view-image.component';
import { ChatService } from '../services';
import { cdkOverlayClass, ChatMessageStatus, MessageStatus, MessageType } from '../shared/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@app/core/services';
import { finalize } from 'rxjs/operators';
import { IdObject } from '@app/models/id-object';
import { DoIfConfirmed } from '@app/core/decorators';

@UntilDestroy()
@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit {
  @Input() message: Message;
  @Input() userChatId: number;
  @Input() userId: number;
  @Input() isThread: boolean = false;
  @Input() showDivider: boolean = false;
  @Input() showSender: boolean = true;
  @Output() openMessageThread = new EventEmitter<Message>();
  @ViewChild('viewImage') viewImage: TemplateRef<any>;

  @HostBinding('class.mine') get isMine(): boolean {
    return this.message.sender.id === this.userId;
  }

  imageMaxHeight: number = 120;
  imageMargin: number = 10;
  resending = false;
  creationFormattedTime: string;

  readonly MessageStatus = MessageStatus;
  readonly MessageType = MessageType;

  constructor(
    private dialog: MatDialog,
    private renderer: Renderer2,
    private chatService: ChatService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.creationFormattedTime = ISOTimeToDaysHoursMinutes(this.message.created_at);
  }

  openImageView(id: number): void {
    this.renderer.addClass(document.body, cdkOverlayClass);
    const files = this.message.files.filter(file => this.isImage(file.content_type));
    this.dialog.open(ViewImageComponent, {
      data: { id, files }
    })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.renderer.removeClass(document.body, cdkOverlayClass);
      });
  }

  setFilesHeight(): number {
    let images = [];
    let docs = [];
    this.message.files.forEach(file => {
      if (this.isImage(file.content_type)) {
        images.push(file.file);
      }
      if (this.isDocument(file.content_type)) {
        docs.push(file.file);
      }
    });

    let height = 0;
    if (images.length) {
      height += images.length * this.imageMaxHeight + images.length * this.imageMargin;
    }
    if (docs.length) {
      height += 24 * docs.length + this.imageMargin * docs.length - 1;
    }

    return height;
  }

  answerToThread(message: Message): void {
    this.openMessageThread.emit(message);
  }

  getMessageDeliveryStatus(): string {
    if (this.message.status === MessageStatus.sent) {
      if (!this.message.is_viewed) {
        return ChatMessageStatus.delivered;
      } else {
        return ChatMessageStatus.viewed;
      }
    }
    return '';
  }

  resendMessage(id: number): void {
    this.resending = true;
    this.chatService.resendMessage(id).pipe(
      finalize(() => {
        this.resending = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe({
      error: (error) => this.notificationsService.showError(error)
    });
  }

  @DoIfConfirmed({ text: 'messageCenter.deleteMessageConfirmation' })
  deleteMessage(messageId: number): void {
    this.chatService.deleteMessage(messageId)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error) => this.notificationsService.showError(error)
      });
  }

  isImage(type: string): boolean {
    return type.startsWith('image/');
  }

  isDocument(type: string): boolean {
    return type.startsWith('application/') || type.startsWith('text/');
  }

  getFormattedTime(time: string): string {
    return ISOTimeToDaysHoursMinutes(time);
  }

  trackById(_, item: IdObject): string | number {
    return item.id;
  }
}
