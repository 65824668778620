<app-loading *ngIf="isLoading" class="loading"></app-loading>

<ng-container *ngIf="!isLoading">
  <section class="caregiver">
    <span class="caregiver__title">{{ "visitDetails.tabBlasting_field_caregiver" | translate }}</span>
    <app-caregiver-cell
      [visitId]="visitId"
      [visitStatus]="visitStatus"
      (selectChanged)="caregiverSelectChange($event)"
    ></app-caregiver-cell>
    <button
      matRipple
      class="app-button app-button_blue"
      [disabled]="!selectedCaregiver || isCaregiverBlasting"
      (click)="blastingCaregiver()">
      <mat-spinner *ngIf="isCaregiverBlasting" class="spinner" diameter="16" strokeWidth="2"></mat-spinner>
      {{ "visitDetails.tabBlasting_button_requestCaregiver" | translate }}
    </button>
    <button
      matRipple
      class="app-button app-button_green"
      [disabled]="isAllBlasting || visitStatus === visitStatuses.completed"
      (click)="blastingToAll()">
      <mat-spinner *ngIf="isAllBlasting" class="spinner" diameter="16" strokeWidth="2"></mat-spinner>
      {{ "visitDetails.tabBlasting_button_blasting" | translate }}
    </button>
  </section>

  <section class="blasting-table">
    <mat-table
      class="table-expandable"
      [dataSource]="items"
      [trackBy]="trackById"
      multiTemplateDataRows>

      <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef>{{ "visitDetails.tabBlasting_column_number" | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          <div class="offers-count" *ngIf="row.new_offers_count > 0">+{{ row.new_offers_count }}</div>
          <div>{{ row.number }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>{{ "visitDetails.tabBlasting_column_dateCreate" | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ row.created_at | date: 'MM/dd/YYYY hh:mm a' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="author">
        <mat-header-cell *matHeaderCellDef>{{ "visitDetails.tabBlasting_column_author" | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ row.creator_detail.full_name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offers">
        <mat-header-cell *matHeaderCellDef>{{ "visitDetails.tabBlasting_column_offers" | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          {{ row.offers_count }}/{{ row.accepted_offers_count }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>{{ "visitDetails.tabBlasting_column_status" | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row">
          <app-status-pill
            [label]="row.status_value"
            [status]="row.status"
            [Enum]="BlastingStatuses">
          </app-status-pill>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>

        <mat-cell *matCellDef="let row" [class.disabled]="row.status !== BlastingStatuses.opened">
          <!--change status to completed-->
          <button matRipple (click)="changeBlastingStatus($event, row.id, BlastingStatuses.completed)">
            <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
          </button>
          <!--change status to cancelled-->
          <button matRipple (click)="changeBlastingStatus($event, row.id, BlastingStatuses.cancelled)">
            <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="expanded-container"
               [class.expanded]="element.id === expandedElement?.id"
               [@detailExpand]="element.id === expandedElement?.id ? 'expanded' : 'collapsed'">

            <app-nested-blasting-table
              *ngIf="expandedElement?.id === element.id"
              [blastingId]="element.id"
              [offerStatus]="element.status">
            </app-nested-blasting-table>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

      <mat-row *matRowDef="let element; columns: displayedColumns;"
        [class.btn-expanded-row]="expandedElement?.id === element.id"
        (click)="onRowClick(element)">
      </mat-row>

      <mat-row class="expanded-row" *matRowDef="let row; columns: ['expandedDetail']"></mat-row>
    </mat-table>

    <div *ngIf="!items.length && !isLoading" class="no-found">
      {{ 'commonNotifications.noResultsFound' | translate }}
    </div>
  </section>
</ng-container>
