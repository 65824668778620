import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Identifier } from '../chip/chip.component';
import { TreeNodeRoute } from '@app/models/tree-node.model';
import { EditorInfoCard } from '@app/models/editor-panel.model';
import { MenuNodeNotificationsDictionary } from '@app/shared/editor-panel/editor-menu/editor-menu.component';
import { AllEnums } from '@app/shared/components/status-pill/status-pill.constants';

@Component({
  selector: 'app-editor-panel-routing',
  templateUrl: './editor-panel-routing.component.html',
  styleUrls: ['./editor-panel-routing.component.scss']
})
export class EditorPanelRoutingComponent {
  @Input() editorInfoCard: EditorInfoCard;
  @Input() noimage: boolean;
  @Input() Enum: AllEnums;
  @Input() titleWithoutCard: string;
  @Input() menuNodes: TreeNodeRoute[];
  @Input() invalidMenuNodes: Array<string>;
  @Input() menuNodesWithNotifications: MenuNodeNotificationsDictionary;
  @Input() activeNode: string;
  @Input() identifier: Identifier = null;
  @Input() idTitle: string = 'userDetails.editMenu_field_userNumber';
}
