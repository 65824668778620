import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MENU_ITEMS_LINKS, MENU_ITEMS_NAMES } from '@app/shared/constants';

@Component({
  selector: 'app-billing-button-links',
  templateUrl: './billing-button-links.component.html',
  styleUrls: ['./billing-button-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingButtonLinksComponent {
  @Input() placementPageLink: string;
  @Input() isLoading: boolean;

  buttonLinks = [
    { label: MENU_ITEMS_NAMES.MAIN_BATCH, icon: 'receipt_long', link: MENU_ITEMS_LINKS.MAIN_BATCH },
    { label: MENU_ITEMS_NAMES.EDI_BATCH, icon: 'payments', link: MENU_ITEMS_LINKS.INVOICING },
    { label: MENU_ITEMS_NAMES.PRIVATE_PAY, icon: 'monetization_on', link: MENU_ITEMS_LINKS.PRIVATE_PAY },
    { label: MENU_ITEMS_NAMES.PAYROLL_BATCH, icon: 'request_quote', link: MENU_ITEMS_LINKS.PAYROLL_BATCH },
  ];

  constructor(private router: Router) {
  }

  goToBillingPage(page: string): void {
    this.router.navigate([page]);
  }

}
