import { environment } from "environments/environment";

// Message center config
export const MC_CONFIG = {
  baseHost: environment.mcHost,
  baseProtocol: environment.baseProtocol,
  baseWsProtocol: environment.baseWsProtocol,
  basePath: '/api/v1/',
  basePort: environment.mcPort,
  baseWsPort: environment.mcWsPort
};

// Home Care config
export const HC_CONFIG = {
  baseHost: environment.baseHost,
  baseProtocol: environment.baseProtocol,
  baseWsProtocol: environment.baseWsProtocol,
  basePath: '/api/v1/',
  basePort: environment.basePort,
  baseWsPort: environment.baseWsPort
};
