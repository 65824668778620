<mat-table class="availability-table" [dataSource]="daysSchedule">
  <ng-container matColumnDef="day">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>

    <mat-cell *matCellDef="let row">
      {{ row.name | translate | uppercase }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateFrom">
    <mat-header-cell *matHeaderCellDef>
      {{ "caregiverDetails.availability_exclusions_popup_column_from" | translate }}
    </mat-header-cell>

    <mat-cell *matCellDef="let row">
      <app-ampm-field 
        [initialTime]="row.time_from"
        [defaultTime]="defaultStartTime"
        (timeValueChanged)="timeChange($event, row, 'time_from')"
      ></app-ampm-field>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateTo">
    <mat-header-cell *matHeaderCellDef>
      {{ "caregiverDetails.availability_exclusions_popup_column_to" | translate }}
    </mat-header-cell>

    <mat-cell *matCellDef="let row">
      <div class="time-error">
        <app-ampm-field 
          [initialTime]="row.time_to"
          [defaultTime]="defaultEndTime"
          (timeValueChanged)="timeChange($event, row, 'time_to')">
        </app-ampm-field>
        <mat-icon *ngIf="row.time_to && !row.valid" [matTooltip]="'commonErrors.date_endTimeIsEarlierThanStartTime' | translate">error</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dayOff">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>

    <mat-cell *matCellDef="let row">
      <mat-slide-toggle [(ngModel)]="row.day_off" (ngModelChange)="dayOffChange()"></mat-slide-toggle>
      <span class="toggle-label">{{ "caregiverDetails.availability_exclusions_popup_switcher_notAvailableAllDay" | translate }}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
