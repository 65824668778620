import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['loading.component.scss']
})
export class LoadingComponent {
  @Input() size: 'extra_small' | 'small' | 'medium' | 'large' = 'medium';
}
