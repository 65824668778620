import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';

import { ConversationType, DirectChatPayload, UserChat } from '@app/pages/message-center/models/chat.model';
import { MessageCenterAuthService, MessageCenterService } from '@app/pages/message-center/services';
import { ModuleSubscription, NotificationsService, RealtimeService } from '@app/core/services';
import { LoadingHandler } from '@app/core/loading-handler';
import { WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';
import { filterWsEvents, getWsData } from '@app/core/services/websocket/rxjs-ws-operators';
import { Message } from '@app/pages/message-center/models/message.model';
import { cdkOverlayClass } from '@app/pages/message-center/shared/constants';
import { CreateGroupComponent } from '@app/pages/message-center/create-group/create-group.component';
import { Observable } from 'rxjs';
import { MessagesComponent } from '@app/pages/message-center/messages-list/messages.component';
import { MCProfile, User } from '@app/pages/message-center/models/user.model';

@UntilDestroy()
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(MessagesComponent) messagesComponent: MessagesComponent;

  @Input() loadChat$?: Observable<UserChat>;
  @Input() chatId: number;
  @Input() createChatData?: DirectChatPayload;
  @Input() threadMessage?: Message;
  @Input() showMessages = true;
  @Input() showSendForm = true;
  @Input() sendImmediatelyWhenUploadFile = false;
  @Input() readNewMessages = true;
  @Input() showUnreadMessagesInHeader = false;

  @Output() threadMessageSelect = new EventEmitter<Message>();

  loadingHandler = new LoadingHandler();
  chat: UserChat;
  realtimeModuleSubscription: ModuleSubscription;

  private profile: MCProfile = this.messageCenterAuthService.getProfile();

  constructor(private messageCenterService: MessageCenterService,
              private messageCenterAuthService: MessageCenterAuthService,
              private cdr: ChangeDetectorRef,
              private notificationsService: NotificationsService,
              private realtimeService: RealtimeService,
              private renderer: Renderer2,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.loadChatDetails();
  }

  editGroupChat(): void {
    this.renderer.addClass(document.body, cdkOverlayClass);
    this.dialog.open(CreateGroupComponent, {
      width: '100%',
      maxWidth: '596px',
      maxHeight: '90vh',
      restoreFocus: false,
      autoFocus: false,
      data: { chatId: this.chat?.id }
    })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.renderer.removeClass(document.body, cdkOverlayClass);
      });
  }

  private loadChatDetails(): void {
    const finish = this.loadingHandler.showLoading();

    (this.loadChat$ ? this.loadChat$ : this.messageCenterService.getUserChatById(this.chatId))
      .pipe(finalize(finish), untilDestroyed(this))
      .subscribe((chat: UserChat) => {
        this.chat = chat;
        this.subscribeToRealtime();
      }, error => this.notificationsService.showError(error));
  }

  private subscribeToRealtime(): void {
    this.realtimeModuleSubscription?.unsubscribe();

    this.realtimeModuleSubscription = this.realtimeService.subscribeToModule({
      user_id: this.profile.id,
      module: WsConnectionModule.chat,
      module_pk: this.chat.id
    });

    this.realtimeService.messages$
      .pipe(filterWsEvents<UserChat>([WsEvent.chatUpdating]), untilDestroyed(this))
      .subscribe((chat) => {
        if (chat.id === this.chat.id) {
          this.chat = { ...this.chat, ...chat };
          this.cdr.markForCheck();
        }
      });

    this.realtimeModuleSubscription.moduleMessages$.pipe(
      getWsData(),
      untilDestroyed(this)
    ).subscribe((data) => {
      const payload = data.payload;

      switch (data.event) {
        case WsEvent.userStatusUpdating:
          this.handleUserStatusUpdating(payload);
          break;

        case WsEvent.newMessage:
          this.handleNewMessage(payload);
          break;

        case WsEvent.userViewedMessage:
          this.chat = { ...this.chat, unread_messages_ids: this.chat.unread_messages_ids.filter(id => id !== payload.id) };
          this.cdr.markForCheck();
          break;
      }
    });
  }

  private handleUserStatusUpdating(payload: Partial<User>): void {
    if (this.chat.conversation_type === ConversationType.direct && payload.id === this.chat.direct_to.id) {
      this.chat = { ...this.chat, direct_to: { ...this.chat.direct_to, ...payload, was_last_online: new Date().toISOString() } };
      this.cdr.markForCheck();
    }
  }

  private handleNewMessage(payload: Message): void {
    const isOwnMessage = payload.sender.id === this.profile.id;

    if (!payload.reply_to) {
      const alreadyHasMessage = this.chat.unread_messages_ids.some(id => id === payload.id);
      if (!isOwnMessage && !alreadyHasMessage) {
        this.chat = { ...this.chat, unread_messages_ids: [...this.chat.unread_messages_ids, payload.id] };
        this.cdr.markForCheck();
      }
    }
  }

  ngOnDestroy(): void {
    this.realtimeModuleSubscription?.unsubscribe();
  }
}
