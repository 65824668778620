<app-loading *ngIf="isLoading" class="loading"></app-loading>

<div *ngIf="visit" class="popup visit-details">
  <div class="popup__header">
    <div class="visit-details__header">
      <div class="visit-status">
        <app-status-cell
          [currentStatus]="visit.status"
          [isColored]="true"
          [visitId]="data.visitId"
          (statusChanged)="setNewStatus($event)">
          <span class="visit-number" [matTooltip]="'visitDetails.number_tooltip' | translate">
            {{ visit.number }}
          </span>
        </app-status-cell>
      </div>

      <div class="visit-details__client page-link"
           [matTooltip]="'visitDetails.header_patientName_tooltip' | translate"
           (click)="openProfilePage('patients', visit.patient, { tab: menuTabs.PERSONAL_INFORMATION })">
        {{ visit.patient_detail.full_name }}
      </div>
    </div>
    <button mat-icon-button class="close-icon" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div matDialogContent>
    <div class="visit-details__main main">
      <div class="main__item">
        <span class="main__label">{{ "visitDetails.header_field_patientPhone" | translate }}</span>
        <span class="main__value page-link" [matTooltip]="'visitDetails.header_patientPhone_tooltip' | translate">
          <a href="tel:{{ visit.patient_detail.phone_number }}">{{ visit.patient_detail.phone_number | phone }}</a>
        </span>
      </div>
      <div class="main__item">
        <span class="main__label">{{ "visitDetails.header_field_clientID" | translate }}</span>
        <span class="main__value page-link"
              [matTooltip]="'visitDetails.header_patientId_tooltip' | translate"
              (click)="openProfilePage('patients', visit.patient, { tab: menuTabs.PERSONAL_INFORMATION })">
          {{ visit.patient_detail.number }}
        </span>
      </div>
      <div class="main__item">
        <span class="main__label">{{ "visitDetails.header_field_coordinator" | translate }}</span>
        <span class="main__value">
          <ng-container *ngFor="let coordinator of visit.patient_detail.coordinators; index as i">
            <span class="page-link"
                  [matTooltip]="'visitDetails.header_coordinator_tooltip' | translate"
                  (click)="openProfilePage('users', coordinator.id)">
              {{ coordinator.full_name }}<ng-container *ngIf="i + 1 < visit.patient_detail.coordinators.length">, </ng-container>
            </span>
          </ng-container>
        </span>
      </div>
      <div class="main__item">
        <span class="main__label">{{ "visitDetails.header_field_authorization" | translate }}</span>
        <span class="main__value page-link"
              [matTooltip]="'visitDetails.header_authorization_tooltip' | translate"
              (click)="openProfilePage('patients', visit.patient, { tab: menuTabs.AUTHORIZATIONS, auth: visit.authorization })">
          {{ visit.authorization_detail.code }}
        </span>
      </div>
      <div class="main__item">
        <span class="main__label">{{ "visitDetails.header_field_service" | translate }}</span>
        <span class="main__value" [matTooltip]="'visitDetails.header_service_tooltip' | translate">
          {{ visit.service_name }}
          <ng-container *ngIf="visit.is_nurse">({{ visit.service_type_value }})</ng-container>
        </span>
      </div>
    </div>

    <app-visit-attentions [attentions]="visit.attentions"></app-visit-attentions>

    <div class="visit-details__tabs">
      <div *ngFor="let tab of tabs" class="visit-details__tab"
           [class.active]="tab.name === currentTab"
           [class.has-attentions]="tabHasAttentions(tab)"
           (click)="changeTab(tab.name)">
        <mat-icon class="material-icons-two-tone"
                  [ngClass]="tabHasAttentions(tab) ? 'icon-red' : (tab.name === currentTab ? 'icon-blue' : 'icon-gray')"
        >{{ tab.icon }}</mat-icon>
       <span> {{ tab.name | translate }} </span> <span class="attentions-count"> {{ tab.attentions }} </span>
      </div>
    </div>

    <app-visit-tab
      *ngIf="currentTab === TabName.visit"
      #visitTabFormComponent
      [visitId]="data.visitId"
      [visitStatus]="visit.status"
    ></app-visit-tab>

    <app-schedule-tab
      *ngIf="currentTab === TabName.schedule"
      #visitTabFormComponent
      [visitId]="data.visitId"
    ></app-schedule-tab>

    <app-billing-tab *ngIf="currentTab === TabName.billing" [visitId]="data.visitId" [visitStatus]="visit.status"
                     #visitTabFormComponent
    ></app-billing-tab>

    <app-payroll-tab *ngIf="currentTab === TabName.payroll" [visitId]="data.visitId" [visitStatus]="visit.status"
                     #visitTabFormComponent
    ></app-payroll-tab>

    <app-blasting-tab *ngIf="currentTab === TabName.blasting"
                      [visitId]="data.visitId"
                      [visitStatus]="visit.status"
    ></app-blasting-tab>

    <app-history-tab *ngIf="currentTab === TabName.history" [visitId]="data.visitId"></app-history-tab>
  </div>
</div>
