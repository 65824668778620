import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Language } from '@app/models/language.model';

const languages = [
  { name: 'languages.en_us', langCode: 'en-us' },
  { name: 'languages.en_gb', langCode: 'en-gb' },
  { name: 'languages.es', langCode: 'es' }
];

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly STORAGE_KEY = 'language';
  private readonly DEFAULT_LANGUAGE_CODE = 'en-us';

  private isInited: boolean = false;
  private languagesList = languages || [];
  currentLang: Language = new Language();

  constructor(
    private translate: TranslateService
  ) { }

  init(): void {
    if (this.isInited) {
      return;
    }
    this.isInited = true;
    this.initLanguagesList();
    this.setDefaultLang();
    this.getSavedLanguage();
  }

  switchLanguage(langCode: string): void {
    if (this.currentLang.langCode === langCode) {
      return;
    }
    this.translate.use(langCode);
    this.currentLang = this.languagesList.find(item => item.langCode === langCode);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(langCode));
  }

  getLanguages(): Language[] {
    return languages;
  }

  private setDefaultLang(): void {
    this.translate.setDefaultLang(this.DEFAULT_LANGUAGE_CODE);
  }

  private initLanguagesList(): void {
    const languagesList = this.languagesList.map(lang => lang.langCode);

    this.translate.addLangs(languagesList);
  }

  private getSavedLanguage(): void {
    let languageCode = JSON.parse(localStorage.getItem(this.STORAGE_KEY));
    if (!languageCode) {
      const browserLang = this.translate.getBrowserCultureLang().toLowerCase();
      languageCode = this.languagesList.find(language => language.langCode === browserLang)?.langCode || this.DEFAULT_LANGUAGE_CODE;
    }
    if (languageCode) {
      this.switchLanguage(languageCode);
    }
  }
}
