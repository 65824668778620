import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayModule } from '@angular/cdk/overlay';

import { MaterialModule } from './material.module';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { ThinLoaderComponent } from './components/loader/thin-loader.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ChatImageComponent } from './components/chat-image/chat-image.component';
import { ChatDocComponent } from './components/chat-doc/chat-doc.component';
import { SelectChipInputComponent } from './components/select-chip-input/select-chip-input.component';
import { TrimInputModule } from '@app/shared/directives/trim-input/trim-input.module';

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  ScrollingModule,
  OverlayModule,
  TrimInputModule,
];

const components = [
  FormFieldComponent,
  UserAvatarComponent,
  ThinLoaderComponent,
  FileUploadComponent,
  ChatImageComponent,
  ChatDocComponent,
  SelectChipInputComponent
];

@NgModule({
  imports: [
    ...modules
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...modules,
    ...components,
  ]
})
export class SharedModule {
}
