import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { FilterSettings, WDGeneralTabResponse } from '@app/models/welcome-dashboard/general-tab.model';
import { WDTabs } from '@app/models/welcome-dashboard/tab.model';

export type TabInfoResponse = WDGeneralTabResponse;

@Injectable({
  providedIn: 'root'
})
export class WelcomeDashboardService {

  generalTabSettings$: BehaviorSubject<FilterSettings> = new BehaviorSubject<FilterSettings>(null);

  private _showDialog = false;

  constructor(
    private httpService: HttpService
  ) { }

  get showDialog(): boolean {
    return this._showDialog;
  }

  set showDialog(value: boolean) {
    this._showDialog = value;
  }

  getTabInfo(tab: WDTabs): Observable<TabInfoResponse> {
    return this.httpService.GET<TabInfoResponse>(`${ URLS.welcome_dashboard }${ tab }/`);
  }

  getFilterSettings(): Observable<FilterSettings> {
    return this.httpService.GET<FilterSettings>(`${ URLS.welcome_dashboard }metrics_filter/`);
  }

  updateFilterSettings(data: FilterSettings): Observable<FilterSettings> {
    return this.httpService.PUT<FilterSettings>(`${ URLS.welcome_dashboard }metrics_filter/`, data);
  }
}
