<app-loading *ngIf="loadingHandler.loading"></app-loading>

<app-chat-header [hidden]="loadingHandler.loading"
                 [chat]="chat"
                 [showUnreadMessages]="showUnreadMessagesInHeader"
                 [isThread]="!!threadMessage"
                 (groupChatEdit)="editGroupChat()"
></app-chat-header>

<app-messages [hidden]="loadingHandler.loading || !showMessages"
              [chatId]="chatId"
              [threadMessage]="threadMessage"
              [chatMembers]="chat?.direct_to ? [chat?.direct_to] : chat?.members"
              [chatType]="chat?.conversation_type"
              [readNewMessages]="readNewMessages"
              (threadMessageSelect)="threadMessageSelect.emit($event)">
  <app-typing-users *ngIf="chat" [chat]="chat"></app-typing-users>
</app-messages>

<app-send-message-form [hidden]="loadingHandler.loading || !showSendForm"
                       [chat]="chat"
                       [chatWebsocket]="realtimeModuleSubscription?.websocket"
                       [sendImmediatelyWhenUploadFile]="sendImmediatelyWhenUploadFile"
                       [threadMessageId]="threadMessage?.id"
></app-send-message-form>

