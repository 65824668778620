<div class="message">
  <div class="message__header">
    <span class="message__title"> {{ 'messageCenter.createMessage_title' | translate }} </span>
    <button matRipple class="btn-close" (click)="close()">
      <mat-icon class="material-icons btn-close__icon">close</mat-icon>
    </button>
  </div>

  <div matDialogContent>
    <form
      class="message__form"
      novalidate
      [formGroup]="form"
      autocomplete="off">

      <app-form-field fieldClasses="new-message send-to" [label]="'messageCenter.createMessage_sendTo' | translate">
        <ng-container *ngIf="userChat.conversation_type === conversationsTypes.direct; else groupMessage">
          {{ userChat.name }}
        </ng-container>
        <ng-template #groupMessage>
          <div>{{ clients.length }} {{ 'messageCenter.members' | translate }}
            <span class="toggle-clients" (click)="toggleClients()">
              {{ isShowClients ? 'Hide' : 'Show' }} {{ 'messageCenter.members' | translate }}
            </span>
          </div>

          <div class="recipients" [@showHideClients]="isShowClients ? 'show' : 'hide'">
            <div class="clients">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let client of clients"
                  class="member"
                  [removable]="true"
                  (removed)="removeClient(client.id)">
                  <span class="member__name">{{ client.short_name }}</span>
                  <mat-icon *ngIf="+type.value === messagesTypes.email" matChipRemove class="member__clear">clear</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </ng-template>
      </app-form-field>

      <app-form-field label="{{ 'messageCenter.createMessage_type' | translate }}*" fieldClasses="new-message type">
        <ng-select
          class="app-ng-select"
          appearance="outline"
          bindLabel="title"
          bindValue="value"
          formControlName="type"
          [clearable]="false"
          [searchable]="false"
          [items]="messagesTypesOptions"
          [placeholder]="'placeholders.select' | translate"
          (change)="changeType($event.value)">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{ item.title | translate }}
          </ng-template>
        </ng-select>
      </app-form-field>

      <app-form-field *ngIf="+type.value === messagesTypes.email"
                      label="{{ 'messageCenter.subjectPlaceholder' | translate }}*"
                      fieldClasses="new-message">
        <input
          class="mc-form-field-input"
          type="text"
          appTrimInput
          [placeholder]="'messageCenter.subjectPlaceholder' | translate"
          formControlName="theme"/>
      </app-form-field>

      <app-form-field label="{{ 'messageCenter.createMessage_message' | translate }}*"  fieldClasses="new-message textarea">
        <textarea
          class="mc-form-field-input"
          [placeholder]="'messageCenter.messageInputPlaceholder' | translate"
          appTrimInput
          formControlName="message"
          autocomplete="off"
          maxlength="1000"
        ></textarea>
      </app-form-field>
    </form>
  </div>

  <div class="message__buttons">
    <div class="files">
      <div *ngFor="let file of loadedFiles" class="file">
        <mat-icon class="material-icons icon-close" (click)="removeAttachedFile(file.index)">close</mat-icon>
        <span class="file__name">{{ file.name }}</span>
      </div>
    </div>

    <div class="buttons">
      <button
        matRipple
        class="mc-button mc-button_red"
        (click)="close()">
        <mat-icon class="material-icons-two-tone btn-icon">delete</mat-icon>
        {{ 'buttons.delete' | translate }}
      </button>

      <app-file-upload (filesAdded)="addFiles($event)"></app-file-upload>

      <button
        matRipple
        class="mc-button mc-button_blue btn-send"
        [disabled]="!form.valid || isSending"
        (click)="send()">
        <mat-icon *ngIf="!isSending" class="material-icons-two-tone btn-icon">send</mat-icon>
        <mat-spinner
          *ngIf="isSending"
          class="spinner"
          diameter="16"
          strokeWidth="2"
        ></mat-spinner>
        {{ 'buttons.send' | translate }}
      </button>
    </div>
  </div>
</div>
