<div class="chat-list" #chatsElement>
  <div *ngFor="let userChat of items; trackBy: trackById"
       cdkDrag
       mwlResizable
       #chatElement
       #cdkDragEl="cdkDrag"
       class="chat-item index-{{ userChat.index }}"
       [style.width.px]="userChat.width"
       [style.height.px]="userChat.height"
       [cdkDragBoundary]="chatsElement"
       [class.active]="activeChatId === userChat.id"
       [class.collapsed]="userChat.collapsed"
       (click)="activeChatId = userChat.id"
       (resizeStart)="onResizeStart(chatElement)"
       (resizing)="onResize($event, userChat)"
       (cdkDragStarted)="activeChatId = userChat.id">
    <div class="drag-area top" cdkDragHandle></div>
    <div class="drag-area bottom" cdkDragHandle></div>
    <div class="drag-area left" cdkDragHandle></div>
    <div *ngIf="!userChat.collapsed" class="resize-area top-left" mwlResizeHandle [resizeEdges]="{ top: true, left: true }">
      <div class="resize-icon-wrapper">
        <mat-icon class="icon-resize">filter_list</mat-icon>
      </div>
    </div>

    <div class="header-actions">
      <span class="material-icons icon-action" (click)="openNewMessagePopup(chatComponent.chat)">open_in_new</span>
      <span *ngIf="!userChat.collapsed" class="material-icons icon-action"
            (click)="collapseChat(userChat.id)">remove</span>
      <span *ngIf="userChat.collapsed" class="material-icons icon-action"
            (click)="expandChat(userChat.id, cdkDragEl)">expand_less</span>
      <span class="material-icons icon-action" (click)="deleteItem(userChat)">close</span>
    </div>

    <app-user-avatar *ngIf="!userChat.open_thread"
                     iconSize="32px"
                     [name]="userChat.name"
                     [avatar]="userChat.image"
    ></app-user-avatar>

    <mat-icon *ngIf="userChat.open_thread"
              class="back-icon"
              (click)="onThreadSelect(userChat, null)">
      arrow_back
    </mat-icon>

    <app-chat class="chat"
              #chatComponent
              [chatId]="userChat?.id"
              [showMessages]="!userChat?.collapsed"
              [showSendForm]="!userChat?.collapsed"
              [threadMessage]="userChat.open_thread"
              [readNewMessages]="!userChat.collapsed"
              [showUnreadMessagesInHeader]="userChat.collapsed"
              (threadMessageSelect)="onThreadSelect(userChat, $event)"
    ></app-chat>
  </div>
</div>
