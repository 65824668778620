import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormService, NotificationsService, VisitDetailsService } from '@app/core/services';
import { VisitDetailPayroll, VisitDetailPayrollUpdate } from '@app/models/patient/visit-details/payroll.model';
import { VisitTabFormComponent } from '@app/pages/visit-details/models';
import { VisitStatus } from '@app/models/visits/visits.model';
import { VisitDetailBillingUpdate } from "@app/models/patient/visit-details/billing.model";

@UntilDestroy()
@Component({
  selector: 'app-payroll-tab',
  templateUrl: './payroll-tab.component.html',
  styleUrls: ['./payroll-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayrollTabComponent implements VisitTabFormComponent, OnInit {
  @Input() visitId: number;
  @Input() visitStatus: VisitStatus;

  isLoading: boolean;
  form: FormGroup;
  isSaving: boolean;
  isShowMileage: boolean = false;
  payrollInfo: VisitDetailPayroll;

  constructor(
    private fb: FormBuilder,
    private visitDetailsService: VisitDetailsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private formService: FormService,
  ) {
  }

  get isContentEditable(): boolean {
    return !this.payrollInfo?.is_billed;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadPayroll();

    this.visitDetailsService.getRefreshCurrentTab
      .pipe(untilDestroyed(this))
      .subscribe((response: boolean) => {
        if (response) {
          this.payrollInfo = undefined;
          this.loadPayroll();
        }
      });
  }

  private loadPayroll(): void {
    this.isLoading = true;
    this.cdr.markForCheck();

    this.visitDetailsService.getPayroll(this.visitId).pipe(
      finalize(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe((response: VisitDetailPayroll) => {
        this.payrollInfo = response;
        this.patchForm(response);
      },
      (error: HttpErrorResponse) => {
        this.notificationsService.showError(error);
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      bill_rate: [0],
      bill_rate_notes: [],
      overtime_duration: [0],
      mileage_included: this.fb.group({
        active: [false],
        quantity: [],
        rate: [],
      }),
      travel_time: this.fb.group({
        active: [false],
        quantity: [],
        rate: [],
      }),
    });
  }

  save(onSuccess: () => void = () => null): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.updatePayroll(this.visitId, this.getPayload()).pipe(
      finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe({
        next: (response) => {
          this.payrollInfo = response;
          this.patchForm(response);
          this.form.markAsPristine();
          this.notificationsService.showSuccess('visitDetails.tabPayroll_action_update-success');
          onSuccess();
        },
        error: (err) => {
          this.notificationsService.showError(err);
          this.formService.setResponseErrorsToForm(err, this.form);
        }
      }
    );
  }

  saveOvertimeDuration(): void {
    const val = this.form.value;
    const payload = {
      overtime_duration: val.overtime_duration
    };

    this.patchPayrollData(payload, this.form.get('overtime_duration') as FormControl);
  }

  savePayRate(): void {
    const val = this.form.value;
    const payload = {
      bill_rate: val.bill_rate
    };

    this.patchPayrollData(payload, this.form.get('bill_rate') as FormControl);
  }

  savePayRateNote(): void {
    const val = this.form.value;
    const payload = {
      bill_rate_notes: val.bill_rate_notes
    };

    this.patchPayrollData(payload, this.form.get('bill_rate_notes') as FormControl);
  }

  private patchPayrollData(payload: any, control: FormControl): void {
    this.isSaving = true;
    this.cdr.markForCheck();

    this.visitDetailsService.patchPayroll(this.visitId, payload).pipe(
      finalize(() => {
        this.isSaving = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe({
        next: (response) => {
          const { bill_rate, ...res } = response;
          this.form.patchValue(res);
          this.payrollInfo = response;
          control.markAsPristine();
          this.notificationsService.showSuccess('visitDetails.tabPayroll_action_update-success');
        },
        error: (err) => {
          this.notificationsService.showError(err);
          this.formService.setResponseErrorsToForm(err, this.form);
        }
      }
    );
  }

  private getPayload(): VisitDetailBillingUpdate {
    const payload = this.form.value;
    payload.mileage_included = this.payrollInfo.mileage_included_access ? payload.mileage_included : {};
    payload.travel_time = this.payrollInfo.travel_time_access ? payload.travel_time : {};

    return payload;
  }

  private patchForm(visit: VisitDetailPayroll): void {
    this.form.patchValue({
      ...visit
    });
  }

  showMileage(): void {
    this.isShowMileage = !this.isShowMileage;
  }

  getYesNo(value: boolean) {
    return this.translate.instant(`common.${ value ? 'yes' : 'no' }`);
  }
}
