import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { PhysicianService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { IdFullNameWidgetResponse } from '@app/models/response.model';
import { GetPhysiciansRequestParams } from '@app/models/physician/physician.model';

export class PhysiciansOptionsLoader implements OptionsLoader {
  private physicianService = inject(PhysicianService);

  getOptions(params?: GetPhysiciansRequestParams): Observable<PaginatedResponse<IdFullNameWidgetResponse>> {
    return this.physicianService.getPhysicians({
      widget: 'fk',
      lookup_field: 'full_name',
      limit: 15,
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
