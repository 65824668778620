export function ISOTimeToDaysHoursMinutes(time: string): string {
  const dayNow = new Date();
  const dayMessage = new Date(time);
  dayNow.setHours(0, 0, 0);
  const utcOffset = new Date().getTimezoneOffset();

  function pad(n): string {
    return (n < 10 ? '0' : '') + n;
  }

  if (dayMessage.getTime() >= dayNow.getTime() - utcOffset) {
    return `${ pad(dayMessage.getHours()) }:${ pad(dayMessage.getMinutes()) }`;
  } else {
    return `${ pad(dayMessage.getDate()) }/${ pad(dayMessage.getMonth() + 1) }/${ dayMessage.getFullYear() } ${ pad(dayMessage.getHours()) }:${ pad(dayMessage.getMinutes()) }`;
  }
}

