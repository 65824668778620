import { NestedBlastingStatus } from '@app/models/blasting/blasting.model';
import { GeneralStatus } from '@app/core/enums';
import { StatusesMapper } from '@app/models/statuses-mapper.model';

export const NESTED_BLASTING_STATUSES_MAPPER: StatusesMapper = {
  [NestedBlastingStatus.Accepted]: GeneralStatus.AcceptingOffer,
  [NestedBlastingStatus.DeclinedOffer]: GeneralStatus.DeclinedOffer,
  [NestedBlastingStatus.PendingOffer]: GeneralStatus.PendingOffer,
  [NestedBlastingStatus.Missed]: GeneralStatus.Missed,
  [NestedBlastingStatus.Coordinated]: GeneralStatus.Coordinated,
  [NestedBlastingStatus.Canceled]: GeneralStatus.Cancelled
}