import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { ServiceDiscipline } from '@app/models/patient/authorizations.model';
import { toPaginatedResponse } from '@app/core/rx-operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';

interface Params {
  search?: string;
  payer_id?: number;
  patient_id?: number;
}

export class ServicesOptionsLoader implements OptionsLoader {
  private httpService = inject(HttpService);

  getOptions(params?: Params): Observable<PaginatedResponse<ServiceDiscipline>> {
    return this.httpService.GET(URLS.payer_pricing_discipline, {
      no_limits: 'yes',
      ...(params ?? {})
    }).pipe(toPaginatedResponse());
  }
}
