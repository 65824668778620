import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  AvailableMasterweek,
  GetMasterweeksRequestParams
} from '@app/models/available-masterweeks/available-masterweeks.model';

@Injectable({
  providedIn: 'root'
})
export class AvailableMasterweeksService {

  constructor(
    private http: HttpService
  ) { }

  getMasterweeks(params: GetMasterweeksRequestParams): Observable<PaginatedResponse<AvailableMasterweek>> {
    return this.http.GET(URLS.master_week_available, params);
  }
}
