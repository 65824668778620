export interface VisitTimeLog {
  available_timestamp: string;
  canceled_timestamp: string;
  confirmation_timestamp: string;
  offered_timestamp: string;
  requested_timestamp: string;
  scheduled_timestamp: string;
  missed_timestamp: string;
  en_route_timestamp: string;
  no_show_timestamp: string;
  in_progress_timestamp: string;
  completed_timestamp: string;
}

export enum VisitStatus {
  available,
  canceled,
  offered,
  requested,
  scheduled,
  missed,
  enRoute,
  noShow,
  inProgress,
  completed
}