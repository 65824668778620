import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef, HostBinding } from '@angular/core';
import * as moment from 'moment/moment';

import { ConversationType, UserChat } from '@app/pages/message-center/models/chat.model';
import { OnlineStatus } from '@app/pages/message-center/models/user.model';
import { interval } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

const UPDATE_LAST_SEEN_INTERVAL = 10000;

@UntilDestroy()
@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHeaderComponent implements OnInit {
  readonly ConversationType = ConversationType;
  readonly OnlineStatus = OnlineStatus;

  @Input() chat: UserChat;
  @Input() isThread = false;
  @Input() showUnreadMessages = false;

  @Output() groupChatEdit = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    interval(UPDATE_LAST_SEEN_INTERVAL)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());
  }

  getTimeAgo(date: string): string {
    return moment(date).fromNow();
  }

  editGroupChat(): void {
    this.groupChatEdit.emit();
  }
}
