import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Identifier } from '../chip/chip.component';
import { TreeNode } from '@app/models/tree-node.model';
import { EditorInfoCard } from '@app/models/editor-panel.model';
import { MenuNodeNotificationsDictionary } from '@app/shared/editor-panel/editor-menu/editor-menu.component';
import { AllEnums } from '../components/status-pill/status-pill.constants';

@Component({
  selector: 'app-editor-panel',
  templateUrl: './editor-panel.component.html',
  styleUrls: ['./editor-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorPanelComponent {
  @Input() editorInfoCard: EditorInfoCard;
  @Input() Enum: AllEnums;
  @Input() noimage: boolean;
  @Input() titleWithoutCard: string;
  @Input() menuNodes: TreeNode[];
  @Input() invalidMenuNodes: Array<string>;
  @Input() menuNodesWithNotifications: MenuNodeNotificationsDictionary;
  @Input() activeNode: string;
  @Input() identifier: Identifier = null;
  @Input() idTitle: string = 'userDetails.editMenu_field_userNumber';
  @Output() activeNodeChanged = new EventEmitter<string>();

  changeActiveNode(event: string): void {
    this.activeNodeChanged.emit(event);
  }
}
