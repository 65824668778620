import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AllEnums, statusesMap, statusesStyles } from './status-pill.constants';
import { StatusStylesConfig } from '@app/models/statuses-mapper.model';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusPillComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() status: AllEnums;
  @Input() Enum: AllEnums;

  statusConfig: StatusStylesConfig;

  readonly DEFAULT_CONFIG: StatusStylesConfig = {
    bgColor: '',
    color: ''
  };

  constructor() { }

  ngOnInit(): void {
    this.setStatusConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      if (!changes.status.firstChange && changes.status.previousValue !== changes.status.currentValue) {
        this.setStatusConfig();
      }
    }
  }

  private setStatusConfig(): void {
    const generalStatus: number = statusesMap.get(this.Enum)[this.status];
    this.statusConfig = statusesStyles[generalStatus] || this.DEFAULT_CONFIG;
  }
}
