export const cdkOverlayClass = 'cdk-global-overlay-position';

export const allowedMIMETypes = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'text/plain',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const groupsTypes = [
  { value: 0, title: 'Public' },
  { value: 1, title: 'Private' }
];

export enum MessageType {
  sms = 0,
  email = 1,
  internal = 2
}

export enum MessageStatus {
  draft = 0,
  created = 1,
  processing = 2,
  sent = 3,
  notSent = 4
}

export enum ChatMessageStatus {
  sent = 'sent',
  delivered = 'delivered',
  viewed = 'viewed'
}

