<div *ngIf="!!chatId"
     class="messages"
     cdkScrollable
     (scroll)="onScroll()">

  <ng-content></ng-content>

  <div class="empty-space-if-no-scroll"></div>

  <app-chat-message *ngFor="let message of items; trackBy: trackById; let messageIndex = index"
                    [message]="message"
                    [userChatId]="chatId"
                    [userId]="profile.id"
                    [isThread]="isThread"
                    [showSender]="chatType === ConversationType.group && items[messageIndex + 1]?.sender.id !== message.sender.id"
                    (openMessageThread)="threadMessageSelect.emit($event)"
  ></app-chat-message>

  <app-thin-loader *ngIf="isLoading"></app-thin-loader>

  <app-chat-message *ngIf="isThread"
                    [showDivider]="true"
                    [message]="threadMessage"
                    [userChatId]="threadMessage.chat"
                    [userId]="profile.id"
                    [isThread]="true"
  ></app-chat-message>
</div>
