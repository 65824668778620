<div class="avatar">
	<div
		*ngIf="imageUrl"
		class="avatar__img"
		[ngStyle]="{
			'width.px': size,
			'height.px': size,
			'border-radius': borderRadius + 'px',
			'background-color': backgroundColor,
			'background-image': 'url(' + imageUrl + ')'
		}">
	</div>
	<div *ngIf="!imageUrl"
		class="avatar__logo"
		[ngStyle]="{
      'width.px': size,
      'height.px': size,
      'border-radius': borderRadius + 'px',
      'font-size': size/2 + 'px'
		}">
		{{ text }}
	</div>
</div>
