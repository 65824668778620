import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  PayrollBatch,
  PayrollBatchDetails,
  PayrollBatchInfo,
  PayrollBatchNestedDetails, PayrollRecalculateBatch, PayrollRunBatch
} from '@app/models/payroll/payroll-batches.model';
import { ResponseSuccess } from '@app/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class PayrollBatchesService {

  constructor(
    private http: HttpService
  ) { }

  getPayrollBatches(params: object): Observable<PaginatedResponse<PayrollBatch>> {
    return this.http.GET<PaginatedResponse<PayrollBatch>>(URLS.caregiver_invoice_batch, params);
  }

  getPayrollBatchById(batchId: number): Observable<PayrollBatchInfo> {
    return <Observable<PayrollBatchInfo>> this.http
    .GET(`${ URLS.caregiver_invoice_batch }${ batchId }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayrollBatchDetails(query: string): Observable<PaginatedResponse<PayrollBatchDetails>> {
    return <Observable<PaginatedResponse<PayrollBatchDetails>>> this.http
    .GET(`${ URLS.caregiver_invoice_batch_item }${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayrollBatchNestedDetails(clientId: number, batchId: string): Observable<PayrollBatchNestedDetails> {
    return <Observable<PayrollBatchNestedDetails>> this.http
    .GET(`${ URLS.caregiver_invoice_batch_item }${ clientId }/?batch_id=${ batchId }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  recalculatePayrollBatch(payload: PayrollRecalculateBatch): Observable<PayrollRecalculateBatch> {
    return <Observable<PayrollRecalculateBatch>>this.http
    .POST(`${ URLS.caregiver_invoice_batch }recalculate_batch/`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  runPayrollBatch(payload: PayrollRunBatch): Observable<ResponseSuccess> {
    return <Observable<ResponseSuccess>> this.http
    .POST(`${ URLS.caregiver_invoice_batch }run_batch/`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
