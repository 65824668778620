import { GeneralStatus } from '@app/core/enums';
import { PatientStatus } from '@app/models/patient/patient.model';
import { StatusesMapper } from '@app/models/statuses-mapper.model';

export const PATIENT_STATUSES_MAPPER: StatusesMapper = {
  [PatientStatus.Active]: GeneralStatus.Active,
  [PatientStatus.Hold]: GeneralStatus.Hold,
  [PatientStatus.Discharged]: GeneralStatus.Discharged,
  [PatientStatus.HospitalizedObservation]: GeneralStatus.HospitalizedObservation,
  [PatientStatus.HospitalizedAdmitted]: GeneralStatus.HospitalizedAdmitted,
  [PatientStatus.PatientNeverStarted]: GeneralStatus.Closed
}