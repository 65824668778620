import { Injectable } from '@angular/core';
import { HttpService } from './http/http.service';
import { Observable, of } from 'rxjs';
import { GetUsersTeamsRequestParams, UsersTeam } from '@app/models/users-team.model';
import { URLS } from '@app/shared/urls';
import { ExcludeId } from '@app/models/exclude-id';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class UsersTeamsService {
  constructor(private http: HttpService) {
  }

  getTeams<T = PaginatedResponse<UsersTeam>>(params?: GetUsersTeamsRequestParams): Observable<T> {
    return this.http.GET<T>(URLS.company_teams, params);
  }

  deleteTeam(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.company_teams }${ id }/`);
  }

  createTeam(team: ExcludeId<UsersTeam>): Observable<UsersTeam> {
    return this.http.POST<UsersTeam>(URLS.company_teams, team);
  }

  updateTeam(team: UsersTeam): Observable<UsersTeam> {
    return this.http.PUT<UsersTeam>(`${ URLS.company_teams }${ team.id }/`, team);
  }
}
