import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { CommunicationSetup } from '@app/models/settings/communication.model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(
    private http: HttpService
  ) { }

  getTwilio(): Observable<CommunicationSetup> {
    return this.http.GET<CommunicationSetup>(`${ URLS.twilioSetup }`);
  }

  updateTwilio(data: CommunicationSetup): Observable<CommunicationSetup> {
    return this.http.PUT<CommunicationSetup>(`${ URLS.twilioSetup }`, data);
  }
}
