import { PaginationParams } from '@app/shared/interfaces/pagination.class';

export interface VisitHistory {
  id: number;
  change_type: VisitHistoryChangeType;
  change_type_value: string;
  message: string;
  user_short_name: string;
  change_date_time: string;
}

export interface GetVisitHistoryRequestParams extends PaginationParams {
  start?: string;
  end?: string;
  types?: VisitHistoryChangeType[];
}

export enum VisitHistoryChangeType {
  FieldChanged,
  StatusChanged,
  Added,
  Deleted,
  Assign,
  Blasting,
}
